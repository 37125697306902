import React from "react";
import PageTop from "../comps/PageTop";
import img3 from "../assets/journey/history_and_cultural_journey.webp";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/his1.webp";
import Before_Footer from "../comps/Before_footer";
import Pagebreak from "../comps/Pagebreak";

function History() {
  return (
    <>
      <PageTop title="History And Cultural Journey" img={img3} />
      <Pagebreak greyBreakPage={true} />
      <ServicePage
        bg="white"
        // title="History And Cultural Journey"
        subdesc="Unravel the mysteries of India's past and immerse yourself in its vibrant present. From ancient ruins to bustling bazaars, our journey is a captivating adventure through time and culture. Discover hidden gems, experience age-old traditions, and create unforgettable memories in this land of endless fascination."
        heading="Our History And Cultural Journey"
        listtitle=""
        imgurl={img1}
        desc="
       Aatman Sparsh is our special signature tour that encompasses all aspects of life in India. The tour has been designed, developed and executed keeping in mind the fact that our guests need to live in India and not just see India. Each tour is detailed to the extent that the traveler has a deep imprint of India in their heart for all times to come. This is our ultra-luxury journey that is conducted only once a year and number of travelers are limited to 25.
        
        History and culture tours are the perfect beginning or end to any business meeting or conference and will help visitors gain insight into the complex history and cultures that make the world what it is today. Visit ancient landscapes, explore cultural villages and learn more about the layers that make up the fabric of the world’s society.
."
      />
    </>
  );
}

export default History;
