import React, { useEffect, useState } from "react";
import "../styles/BlogPage.scss";
import PageTop from "../comps/PageTop";
import Pagebreak from "../comps/Pagebreak";
import axios from "axios";
import { useParams } from "react-router-dom";
import bg from "../assets/blogs/bg1.jpg"
const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

function BlogPage() {
  const [data, setData] = useState([]);
  const { id } = useParams();

  const fetchBlog = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}blogs/${id}`
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <PageTop title="Blog Details" img={bg} />
      <Pagebreak greyBreakPage={true} />
      <div className="blog-page-parent">
        <div className="blog-parent-overlay">
        
        </div>
        <div className="blog-page-cont">
          <div
            className="blog-new-image bg-cover"
            style={{ backgroundImage: `url(${data.image})` }}
          ></div>
          <div className="para-div">
            <div className="blog-headings">
              <div className="inside-tab">{formatDate(data.date)}</div>
              <div className="inside-tab">{data.category}</div>
            </div>
            <h2>{data.title}</h2>
            <div className="description-tag">

            </div>
            <p
              className="my-para"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPage;
