import React from "react";

import "../styles/ImageBox.scss";

function ImageBox(props) {
  return (
    <div className="img-container">
      <div className="overlay-box">
        {props.imghigh && (
          <div className="highlight-box"> {props.imghigh} </div>
        )}
        <span className="heading bottom-heading">{props.imgheading}</span>
      </div>
      <div
        className="img-box2 bg-img-cover"
        style={{ background: `url('${props.imgurl}')` }}
      ></div>
      <div className="loader-box"></div>
    </div>
  );
}

export default ImageBox;
