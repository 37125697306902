import React from 'react'
import  "../styles/SectionIndicator.scss"


function SectionIndicator(props) {
  return (
    <div className='section-indicator'>
      <div className="line"></div>
      <span className='heading'>{props.indicator}</span>
    </div>
  )
}

export default SectionIndicator
