import React, { useEffect, useState } from "react";
import "../styles/about.scss";
import PageTop from "../comps/PageTop";
import red from "../assets/about-bg.webp";
import FeatureSection from "../comps/FeatureSection";
import aboutImg from "../assets/about-i.jpg";
import Pagebreak from "../comps/Pagebreak";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const About = () => {
  const [value, setValue] = useState(false);

  const handleSlideChange = (swiper) => {
    setValue((prevValue) => !prevValue);
    // if (swiper.activeIndex === 3) {
    //   setTimeout(() => {
    //     swiper.slideTo(0);
    //   }, 5000);
    // }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setValue(prevState => !prevState); // Toggle the state
  //   }, 5250); // Interval in milliseconds (4 seconds)

  //   return () => {
  //     clearInterval(interval); // Clean up the interval on component unmount
  //   };
  // }, []);

  const aboutPageData = [
    {
      indicator: "About Us",
      bg: "",
      direction: "row",
      btntext: "Plan Your Trip",
      btnlink: "/service",
      imghigh: "India",
      imgurl: aboutImg,
      desc: "Travelling is not just about visiting a particular place. It is an experience and later a memory that one carries for a lifetime. The Indian Journey was established with the vision to provide such experiences. We are an inbound and outbound tourism company from India specializing in conducting one of its kind unique journeys across the globe. Every tour by us is crafted to quench travellers' thirst for exploring and experiencing, and non-travellers need to have new and unforgettable experiences. But The Indian Journey is much more than that. Our packages are not just about leisure travels but an opportunity to travel with purpose. Travelling with The Indian Journey means we assist you every step of the way, beginning from the first step of planning. Our wide range of services includes all kinds of assistance that you may need during your travels and your journey of purpose.",
    },
  ];

  return (
    <>
      <div className="about-section-parent parent bg-img-cover">
        <PageTop img={red} title="About Us" />
        <Pagebreak greyBreakPage={false} />
        <div className="about-cont cont">
          <FeatureSection
            indicator={aboutPageData[0].indicator}
            desc={aboutPageData[0].desc}
            bg={aboutPageData[0].bg}
            direction={aboutPageData[0].direction}
            btntext={aboutPageData[0].btntext}
            btnlink={aboutPageData[0].btnlink}
            imghigh={aboutPageData[0].imghigh}
            imgurl={aboutPageData[0].imgurl}
          />
        </div>
      </div>
      <div className="our-mission-parent parent">
        <div className="our-mission-cont cont">
          <div className="our-mission-left-content">
            {!value ? (
              <div className="mission">
                <h3>Our Mission</h3>
                <p>
                  Our mission is to meticulously curate transformative travel
                  experiences. We go beyond destinations, fostering deep
                  connections with cultures and landscapes. We craft journeys
                  that create lasting memories, empowering travellers to
                  discover themselves and the world around them.
                </p>
              </div>
            ) : (
              <div className="vision mission">
                <h3>Our Vision</h3>
                <p className="vision-para">
                  The Indian Journey envisions igniting a spark in travellers,
                  crafting unforgettable experiences that enrich lives and leave
                  an enduring mark on their souls. We believe in the
                  transformative power of travel, fostering connections with
                  destinations that resonate long after the journey ends
                </p>
              </div>
            )}
          </div>
          <div className="our-mission-right-slider">
            <Swiper
              // className="pintu"
              slidesPerView={"auto"}
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop={false}
              navigation={false}
              modules={[Navigation, Autoplay]}
              className="mySwiper"
              onSlideChange={handleSlideChange}
            >
              <SwiperSlide className="mission">
                <div className="overlay">
                  <h3>Mission</h3>
                </div>
              </SwiperSlide>
              <SwiperSlide className="vision">
                {" "}
                <div className="overlay">
                  <h3> Vision</h3>
                </div>{" "}
              </SwiperSlide>
              <SwiperSlide className="mission">
                {" "}
                <div className="overlay">
                  <h3>Mission</h3>
                </div>{" "}
              </SwiperSlide>
              <SwiperSlide className="vision">
                {" "}
                <div className="overlay">
                  <h3>Vision</h3>
                </div>{" "}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
