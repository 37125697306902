import React from 'react'
import "../styles/n_services.scss"
// import "../styles/Service.scss";

import PageTop from '../comps/PageTop'
import Pagebreak from '../comps/Pagebreak'
import red from "../assets/se_bg.jpg"
import newimg from "../assets/service/hotel.jpg"
import travelimg from "../assets/service/travel.webp"
import visaaplication from "../assets/service/visa_application.jpg"
import holiday from "../assets/service/holiday.jpg"
import airticket from "../assets/service/air_ticket.jpg"
import themejourney from "../assets/service/theam.jpg"
import { Link } from 'react-router-dom'
const New_service = (props) => {
    const data = [
        {
            title : "Travel Insurance",
            description : "Problems do not come knocking on the door. Hence, having a safety net to deal with such situations is essential. But when you are travelling, having a safety net can be tricky. Several things could go wrong, such as lost baggage or even accidents. We foresee this problem and provide travel insurance services so that whether you travel in India or abroad, your safety net is with you every step of the way. Our comprehensive coverage ensures you focus on enjoying your trip, knowing we have your back. ",
            image: travelimg,
            btn_word:"Enquire Now",
            btn_Link:props.openfourthform,
            pageRedirect:"/book",
            linkid:"insurance"
        },
        {
            title : "Visa Assistance",
            description : "You are excited about the trip you are about to take. You are planning and shopping and packing. But between all this excitement, you have to take a break to do the boring stuff of filling out forms and standing in lines to get a visa. It will surely put a damper on all that excitement. The Indian Journey is here to give you a great experience at every step, and obtaining your visa is part of it! So plan and pack away because The Indian Journey's Visa Services will take care of your boring stuff!",
            image: visaaplication,
            btn_word:"Enquire Now",
            btn_Link:props.openthirdform,
             pageRedirect:"/book",
            linkid:"visa"

        },
        
        
        {
            title : "Theme-based journey",
            description : "If you are planning a vacation or a getaway trip, what is the first step you take? For almost everyone, it begins with choosing a destination. But what if there is another, far better way? What if you could choose what kind of experience you want and we will arrange the trip according to your requirements? The Indian Journey provides theme-based Journeys: adventure, peace, romantic getaways, or a spiritual journey of self-love and finding purpose. Choose your experience with The Indian Journey!",
            image: themejourney,
            btn_word:"Enquire Now",
            btn_Link:props.opensixthform,
             pageRedirect:"/book",
            linkid:"theme_based_journey"

        },
        {
            title : "Customized Holiday Package",
            description : "Our USP is arranging a wide range of travel packages that suit your every need. Everyone has a different way of viewing and experiencing the world around them. Giving the same package to everyone means restricting one's experience while travelling. With this thought, The Indian Journey devised a solution for customised travel packages. We will always have an excellent package for you no matter where you want to go because the package is designed according to your requirements and expectations.",
            image: holiday,
            btn_word:"Enquire Now",
            btn_Link:props.openfifthform,
             pageRedirect:"/book",
            linkid:"holiday_package"

        },
        {
            title : "Air-Ticketing ",
            description : "No matter which corner of the world you want to explore, get the best deals for air travel. When looking for domestic or international flights, instead of booking the tickets straight away or endlessly browsing the internet for suitable deals, message us and our dedicated representatives will find the best and most suitable deals possible. At The Indian Journey, we are passionate about travelling, so with our expert guidance and exclusive offers, we ensure a seamless booking experience for you!",
            image: airticket,
            btn_word:"Enquire Now",
            btn_Link:props.openfristform,
             pageRedirect:"/book",
            linkid:"flight"

        },
        {
            title : "Hotel Reservations ",
            description : "Making the hotel reservation while travelling can be hectic, especially considering your requirements and budget. With thousands of property listings that include hotels, vacation rentals, resorts, apartments, and guest houses, we ensure you access an extensive range of options at unbeatable prices. Our team guarantees you get the best deal. Our sales representatives are also available around the clock to assist with booking needs, ensuring you find the perfect place to stay during your travels.",
            image: newimg,
            btn_word:"Enquire Now",
            btn_Link:props.opensecondform,
             pageRedirect:"/book",
            linkid:"hotel"

        }
    ]
  return (
    <div className="service-parent parent">
        <PageTop img={red} title="Services" />
        <Pagebreak greyBreakPage={true} />
        <div className="service-container cont">
          <div className="titlebox">
            {/* <h2>Our Great Services</h2> */}
            <p>
              Your Dream Indian Adventure Starts With Our Services The Indian
              Journey goes beyond bookings. We design personalised experiences,
              from themed adventures to stress-free logistics. Explore,
              discover, and create lasting memories. Let's craft your perfect
              Indian odyssey.
            </p>
          </div>
          <div className="service-card">
            {
                data && data.map((data,index)=>{
                    return(
                    <div className="card-box" id={data.linkid} key={index}>
                    <div className="card-content">
                        <h3 className='card-title'>{data.title}</h3>
                        <p>{data.description}</p>
                    </div>
                    <div className="card-image bg-img-cover" style={{background : `url(${data.image})`}}>
                        <Link className="btn" to={data.pageRedirect}  onClick={data.btn_Link} >
                           {data.btn_word} 
                        </Link>
                    </div>
                </div>
                )})
            }
           

          </div>
          </div>
          </div>
  )
}

export default New_service
