import React from "react";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/cul1.png";
import PageTop from "../comps/PageTop";
import img2 from "../assets/journey/culinary_journey.webp";
import Before_Footer from "../comps/Before_footer";
import "../styles/culinary.scss";
import { FaCheck } from "react-icons/fa";
import Pagebreak from "../comps/Pagebreak";
function CulinaryJourney() {
  const cheakdata = [
    {
      id: 1,
      desc: "Explore countries with a rich culinary heritage",
    },
    {
      id: 2,
      desc: "Cook in stunning locations",
    },
    {
      id: 3,
      desc: "Learn authentic recipes from local chefs",
    },
    {
      id: 4,
      desc: "Have lunch with families in their homes",
    },
    {
      id: 5,
      desc: "Shop in local markets for ingredients",
    },
    {
      id: 6,
      desc: "Taste the best street food available",
    },
    {
      id: 7,
      desc: "In between cooking, interact with the locals, unwind and explore your local surroundings.",
    },
  ];
  return (
    <>
      <PageTop title="Culinary Journey" img={img2} />
      <Pagebreak greyBreakPage={true} />
      <div className="culinary-parent parent">
        <div className="culinory-cont cont">
          <div className="top-culinory">
            {/* <h2>Culinary Journey</h2> */}
            <p>
              Embark on an Indian Culinary Journey to explore diverse tastes, cooking methods, and local ingredients. Discover alluring recipes, medicinal plant benefits, and signature spices. Experience a burst of flavours as you travel through India. This unique journey captures the essence of Indian cuisine and offers unforgettable sensory experiences.
            </p>
          </div>
          <div className="bottom-culinory">
            <div className="culinory-left">
              <div className="img-container img-cont">
                <div className="overlay bg-img-cover"></div>
              </div>
            </div>
            <div className="culinary-right">
              <h2 className="cil-heading">Our Culinary Journey</h2>
              <p>
                Small group & tailor-made tours in India & around the world with
                a culinary twist
              </p>
              {cheakdata.map((item, index) => {
                return (
                  <li>
                    <span className="icon">
                      <FaCheck />
                    </span>
                    {item.desc}
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CulinaryJourney;
