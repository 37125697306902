import React from "react";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/study2.webp";
import PageTop from "../comps/PageTop";
import img2 from "../assets/journey/study_journey.webp";
import img3 from "../assets/service/study1.webp";
import img4 from "../assets/service/study3.webp";
import Pagebreak from "../comps/Pagebreak";
function StudyJourney() {
  const cheakdata = ["India", "USA & Canada", "UK & Ireland", "European Union"];
  const cheakdata2 = [
    "Art and Design",
    " ICT",
    "Business and Economics",
    "Hospitality and Catering",
    "History, Government and Politics",
    "Performing Arts, Drama and Theater",
    "Film Studies and Media",
    "Geography",
    "Design and Technology",
    "Fashion",
    "Science",
    "Sport",
    "Health and Social Care",
    "Religious Studies",
    "Maths",
    "Psychology",
  ];
  return (
    <>
      <PageTop title="Study Journey" img={img2} />
      {/* -----------------------------start first section -------------------------------- */}
      <Pagebreak greyBreakPage={true} />
      <ServicePage
        bg="white"
        // title="Study Journey"
        subdesc=" No Studies are complete without travel. Travel is a life teacher in itself and gives us knowledge about many unknown & unseen aspects of life. Indian Pulse is a special initiative of The Indian Journey in which career enhancement is the purpose of travel. We conduct social-economic tours, entrepreneur's journeys, Industry specialization journeys, 'Next-gen' journeys and career grooming journeys."
        imgurl={img1}
        indicator="OUR Study JOURNEY"
        cheakdata={cheakdata}
      />

      {/* --------------------------------start second section--------------------------------- */}
      <ServicePage
        direction="row-reverse"
        imgurl={img3}
        indicator="OUR Study JOURNEY"
        heading="Topics to be Covered"
        cheakdata={cheakdata2}
      />

      {/* ----------------------------------------start third section -------------------- */}
      <ServicePage
        bg="white"
        direction=""
        heading="EDUCATIONAL CONFERENCES"
        imgurl={img4}
        indicator="OUR Study JOURNEY"
        listtitle=""
        desc="Our educational conferences offer a unique experience, combining a tailored tour with a focused subject-based experience. Renowned speakers share their expertise and engage with students through Q&A sessions. Our conferences challenge students' views, motivate them, and provide valuable case studies for classroom application. Our dedicated Events Team ensures a fantastic experience for both you and your students."
      />
    </>
  );
}

export default StudyJourney;
