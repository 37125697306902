import React from "react";
import "../styles/img_card.scss";
import { Link } from "react-router-dom";

function Img_card({moveToTop}) {

  
  return (
    <>
      <div className="img-card-parent parent">
        <div className="img-card-cont cont">
          <div className="top-cont">
            <div className="img-first ">
              <div className="photo-first img-hor">
                <div className="photo study-photo bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Study Journey</h2>
                    </div>
                    <Link className="btn" to="/study_journey" >Read More</Link>
                  </div>
                </div>
              </div>
              <div className="photo-second img-hor">
                <div className="photo sport-journey bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Sport Journey</h2>
                    </div>
                    <Link className="btn" to="/sport" >Read More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="img-third">
              <div className="photo-third img-ver">
                <div className="photo culinary-jorney bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Culinary Journey</h2>
                    </div>
                    <Link className="btn" to="/culinary_journey">Read More</Link>
                  </div>
                </div>
              </div>
              <div className="photo-third img-ver">
                <div className="photo his-cul-journey bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>History & Cultural Journey</h2>
                    </div>
                    <Link className="btn" to="/history" >Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top-cont" style={{ flexDirection: "row-reverse" }}>
            <div className="img-first ">
              <div className="photo-first img-hor">
                <div className="photo acro-yoga-journey  bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Acro Yoga Journey</h2>
                    </div>
                    <Link className="btn" to="/acroyoga" >Read More</Link>
                  </div>
                </div>
              </div>
              <div className="photo-second img-hor">
                <div className="photo event-journey bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Event Journey</h2>
                    </div>
                    <Link className="btn" to="/event" >Read More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="img-third">
              <div className="photo-third img-ver">
                <div className="photo photo-journey bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Photography Journey</h2>
                    </div>
                    <Link className="btn" to="/photography" >Read More</Link>
                  </div>
                </div>
              </div>
              <div className="photo-third img-ver">
                <div className="photo yoga-medi-journey  bg-img-cover">
                  <div className="overlay"></div>
                  <div className="tag">
                    <div className="name">
                     
                      <h2>Yoga & Meditation Journey</h2>
                    </div>
                    <Link className="btn" to="/yoga" >Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Img_card;
