import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'antd';
import countries from '../static/countries.json'; // Adjust path as needed

const CountryAutoComplete = ({ placeholder, onSelectCountry, close }) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const autoCompleteRef = useRef(null);

  // Function to get default 10 alphabetical countries
  const getDefaultCountries = () => {
    return countries
      .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
      .slice(0, 10) // Get the first 10 countries
      .map((country) => ({
        value: country.name,
        label: (
          <div>
            <strong>{country.name}</strong> ({country.code})
          </div>
        ),
      }));
  };

  useEffect(() => {
    // Set default options on component mount
    setOptions(getDefaultCountries());

    // Focus the input field when the component mounts
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, []);

  const onSearch = (searchText) => {
    if (!searchText) {
      setOptions(getDefaultCountries()); // Reset to default options when search text is empty
      return;
    }
    const filteredOptions = countries
      .filter((country) =>
        country.name.toLowerCase().includes(searchText.toLowerCase()) ||
        country.code.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((country) => ({
        value: country.name,
        label: (
          <div>
            <strong>{country.name}</strong> ({country.code})
          </div>
        ),
      }));

    setOptions(filteredOptions);
  };

  const onSelect = (value) => {
    const selectedCountry = countries.find(
      (country) => country.name === value
    );
    if (selectedCountry) {
      onSelectCountry(selectedCountry);
      close();
    }
    console.log('Selected:', selectedCountry);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <AutoComplete
      ref={autoCompleteRef}  // Use ref to access the AutoComplete component
      value={value}
      options={options}
      style={{ width: 300 }}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChange}
      placeholder={placeholder}
      open={true}  // Ensure the dropdown is open when the component is rendered
    />
  );
};

export default CountryAutoComplete;
