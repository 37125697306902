import React, { useState, useEffect } from "react";
import PageTop from "../comps/PageTop";
import "../styles/Book.scss";
import AOS from "aos";
import "aos/dist/aos.css";
//images

import flight from "../assets/forms/flights.svg";
import hotels from "../assets/forms/hotel.svg";
import visa from "../assets/forms/visa.svg";
import insurance from "../assets/forms/insurance.svg";
import theme from "../assets/forms/theme.svg";
import holiday from "../assets/forms/travel package.svg";
import Pagebreak from "../comps/Pagebreak";
import Flight from "./formComponent/Flight";
import Hotel from "./formComponent/Hotel";
import VisaForm from "./formComponent/VisaForm";
import Insurance from "./formComponent/Insurance";
import HolidayPackages from "./formComponent/HolidayPackages";
import ThemeBased from "./formComponent/ThemeBased";
// import Select from 'react-select'
// icons
import blue_flight from "../assets/icons/blued_flight.png";
import grey_flight from "../assets/icons/grey_flight.png";
import blue_visa from "../assets/icons/icon/Visa_Blue.png";
import grey_visa from "../assets/icons/icon/Visa_Gray.png";
import Insurance_Blue from "../assets/icons/icon/Insurance_Blue.png";
import Insurance_Grey from "../assets/icons/icon/Insurance_Grey.png";
import theme_based_journey_Blue from "../assets/icons/icon/theme_based_journey_Blue.png";
import theme_based_journey_Gray from "../assets/icons/icon/theme_based_journey_Gray.png";
import holiday_packages_Blue from "../assets/icons/icon/holiday_packages_ Blue.png";
import holiday_packages_Gray from "../assets/icons/icon/holiday_packages_Gray.png";
import blue_hotel from "../assets/icons/hotel_blued.png";
import grey_hotel from "../assets/icons/hotel_grey.png";

function Book(props) {
  
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      {props.firstform && <PageTop img={flight} title="Book Flight" />}
      {props.secondform && <PageTop img={hotels} title="Book Hotels" />}
      {props.thirdform && <PageTop img={visa} title="VISA" />}
      {props.fourthform && <PageTop img={insurance} title="Insurance" />}
      {props.fifthform && <PageTop img={holiday} title="Holiday packages" />}
      {props.sixthform && <PageTop img={theme} title="Theme based journey" />}
      <div className="book-now bg-img-cover parent">
        <Pagebreak greyBreakPage={true} />
        <div className="book-now-cont cont">
          <div className="forms-div">
            <button
              className={props.firstform ? "form-btn active" : "form-btn"}
              onClick={props.openfristform}
            >
              <span>
                {props.firstform ? (
                  <img src={blue_flight} alt="" />
                ) : (
                  <img src={grey_flight} alt="" />
                )}
              </span>
              <p className={props.firstform ? "tag active" : "tag"}>Flight</p>
            </button>
            <button
              className={props.secondform ? "form-btn active" : "form-btn"}
              onClick={props.opensecondform}
            >
              <span>
                {props.secondform ? (
                  <img src={blue_hotel} alt="" />
                ) : (
                  <img src={grey_hotel} alt="" />
                )}
              </span>
              <p className={props.secondform ? "tag active" : "tag"}>Hotel</p>{" "}
            </button>
            <button
              className={props.thirdform ? "form-btn active" : "form-btn"}
              onClick={props.openthirdform}
            >
              <span>
                {props.thirdform ? (
                  <img src={blue_visa} alt="" />
                ) : (
                  <img src={grey_visa} alt="" />
                )}
              </span>
              <p className={props.thirdform ? "tag active" : "tag"}>Visa</p>
            </button>
            <button
              className={props.fourthform ? "form-btn active" : "form-btn"}
              onClick={props.openfourthform}
            >
              <span>
                {props.fourthform ? (
                  <img src={Insurance_Blue} alt="" />
                ) : (
                  <img src={Insurance_Grey} alt="" />
                )}
              </span>
              <p className={props.fourthform ? "tag active" : "tag"}>Insurance</p>
            </button>
            <button
              className={props.fifthform ? "form-btn active" : "form-btn"}
              onClick={props.openfifthform}
            >
              <span>
                {props.fifthform ? (
                  <img src={holiday_packages_Blue} alt="" />
                ) : (
                  <img src={holiday_packages_Gray} alt="" />
                )}
              </span>
              <p className={props.fifthform ? "tag holiday-tag active" : "tag holiday-tag"}>
                Holiday Packages
              </p>
            </button>
            <button
              className={props.sixthform ? "form-btn active" : "form-btn"}
              onClick={props.opensixthform}
            >
              <span>
                {props.sixthform ? (
                  <img src={theme_based_journey_Blue} alt="" />
                ) : (
                  <img src={theme_based_journey_Gray} alt="" />
                )}
              </span>
              <p className={props.sixthform ? "tag active" : "tag"}>
                Theme Based 
                Journey
              </p>
            </button>
          </div>
          {/* flights form */}
          {props.firstform && <Flight />}
          {/* hotels form */}
          {props.secondform && <Hotel />}
          {/* visa form */}
          {props.thirdform && <VisaForm />}
          {/* insurance form */}
          {props.fourthform && <Insurance />}

          {/* theme form */}
          {props.fifthform && <HolidayPackages setstay={props.setstay} getstay={props.getstay} setrange={props.setrange} getrange={props.getrange}/>}
          {props.sixthform && <ThemeBased />}
        </div>
      </div>
    </>
  );
}

export default Book;
