import React, { useEffect, useState } from "react";
import "../styles/Blog.scss";
import PageTop from "../comps/PageTop";
import blog_img from "../assets/gallery/g-top.jpg";
import Blog_comp from "../comps/Blog_comp";
import Blog_Recent_post from "../comps/Blog_Recent_post";
import Pagebreak from "../comps/Pagebreak";
import axios from "axios";

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [value, setValue] = useState(4);
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}blogs/`);
      setBlogs(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);
  console.log(blogs);

  return (
    <>
      <PageTop img={blog_img} title="Archives" />
      <Pagebreak greyBreakPage={true} />
      <div className="blog-parent parent">
        <div className="blog-container cont">
          <div className="blog-left">
            {blogs &&
              [...blogs]
                .reverse()
                .slice(0, value)
                .map((data, index) => {
                  return (
                    <Blog_comp
                      image={data.image}
                      title={data.title}
                      para={data.description}
                      date={data.date}
                      category={data.category}
                      link={data.id}
                    />
                  );
                })}
            {value <= blogs.length && (
              <button
                onClick={() => setValue(value + 4)}
                className="btn loadmore-btn load-more"
              >
                show more
              </button>
            )}
          </div>

          <div className="blog-right">
            <div className="text-recent">RECENT POST</div>

            {blogs &&
              [...blogs]
                .reverse()
                .slice(0, 4)
                .map((data, index) => {
                  return (
                    <Blog_Recent_post
                      title={data.title}
                      date={data.date}
                      comments={data.category}
                      image={data.image}
                      link={data.id}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
