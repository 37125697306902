import React, { useRef, useState } from 'react';
import '../styles/imageswiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Imageswiper() {
    return (
        <>
            <div className="img-swiper-parent parent">
                <div className="img-swiper-cont cont">
                    <h2 className="img-swiper-hading">Our wide range of services makes sure your every need is fulfilled.</h2>


                    <Swiper
                        spaceBetween={20}
                        centeredSlides={false}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        // pagination={{
                        //     clickable: false,
                        // }}

                        breakpoints={{
                            350: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                            },
                            780: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 50,
                            },
                            1150: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },

                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="img-card">
                                <div className="img-box bg-img-cover img1"></div>
                                <h3 className='img-title'>Destination Wedding</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="img-card">
                                <div className="img-box bg-img-cover img2"></div>
                                <h3 className='img-title'>Medical Tourism</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="img-card">
                                <div className="img-box bg-img-cover img3"></div>
                                <h3 className='img-title'>Vally of Kass</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="img-card">
                                <div className="img-box bg-img-cover img4"></div>
                                <h3 className='img-title'>Firefly's Festival</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="img-card">
                                <div className="img-box bg-img-cover img5"></div>
                                <h3 className='img-title'>La Tomatina</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className="img-card">
                                <div className="img-box bg-img-cover img6"></div>
                                <h3 className='img-title'>Holi</h3>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>

        </>
    )
}

export default Imageswiper