import React, { useState } from 'react'
import "../styles/Pagebreak.scss"

function Pagebreak({greyBreakPage}) {



  return (
    // <div className={ 'greypagebreak parent'}>
     <div className={greyBreakPage ? 'pagebreak parent' : 'greypagebreak parent'}> 

        
      
    </div>
  )
}

export default Pagebreak
