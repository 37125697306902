import React from "react";
import "../styles/Blog_Recent_post.scss";
import { Link } from "react-router-dom";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

function Blog_Recent_post(props) {
  return (
    <>
      <div className="blog-post">
        <div className="left-right">
          <div
            className="left bg-img-cover"
            style={{ background: `url(${props.image})` }}
          ></div>

          <div className="right">
            <h5 className="post-h5">
              <Link to={`/blogPage/${props.link}`}>
                {truncateText(props.title, 8)}
              </Link>
            </h5>
            <div className="date-comments">
              <div className="date">
                <a>{props.date}</a>
              </div>
              <div className="comments">
                <a>{props.comments}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog_Recent_post
