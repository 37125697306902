import React from "react";
import "../../src/styles/terms.scss";
import { Link } from "react-router-dom";
const TermsCondition = () => {
  return (
    <>
      <div className="terms-parent parent">
        <div className="terms-cont cont">
          <h3 className="term">Terms and Conditions</h3>
          <p>
            Welcome to The Indian Journey. These terms and conditions govern
            your use of our website and services. By accessing or using our
            website, you agree to these Terms.
          </p>

          <h3>General Terms</h3>

          <div className="list">
            <h5 className="heading">Service Provider:</h5>
            <p>The Indian Journey, located at 10Biz Park, Viman Nagar, Pune.</p>
          </div>
          <div className="list">
            <h5 className="heading">Email :</h5>
            <p>info@theindianjourney.com</p>
          </div>
          <div className="list">
            <h5 className="heading">Service Offerings:</h5>
            <p>
              We provide bookings for visas, flights, hotels, and other
              travel-related services.
            </p>
          </div>

          <h3>Bookings</h3>

          <div className="list">
            <h5 className="heading">Accuracy of Information:</h5>
            <p>
              You must provide accurate and complete information during the
              booking process. We are not responsible for any issues arising
              from incorrect details provided by you.
            </p>
          </div>
          <div className="list">
            <h5 className="heading">Booking Confirmation:</h5>
            <p>
              Bookings are subject to availability and confirmation. Once
              confirmed, you will receive a booking reference via email.
            </p>
          </div>
          <div className="list">
            <h5 className="heading">Payment:</h5>
            <p>
              Full payment must be made at the time of booking unless otherwise
              stated.
            </p>
          </div>
          <div className="list">
            <h5 className="heading">Cancellation and Refunds:</h5>
            <p>
              Cancellations may be subject to fees, and refunds are processed
              according to our cancellation policy, which varies depending on
              the service booked. Please review the specific cancellation policy
              during booking.
            </p>
          </div>
          <h3>Visas</h3>

          <div className="list">
            <h5 className="heading">Application Process:</h5>
            <p>
              We assist in the visa application process but do not guarantee
              visa approval. The decision is solely at the discretion of the
              relevant authorities.
            </p>
          </div>
          <div className="list">
            <h5 className="heading">Documentation:</h5>
            <p>
              You are responsible for providing the necessary and accurate
              documents required for the visa application. Any delays or
              rejections due to incorrect or incomplete documents are not our
              responsibility.
            </p>
          </div>
          <h3>Flights and Hotels</h3>

          <div className="list">
            <h5 className="heading">Flight Bookings:</h5>
            <p>
              We offer flight bookings based on the availability of airlines.
              Prices and availability are subject to change without notice.
            </p>
          </div>
          <div className="list">
            <h5 className="heading">Hotel Bookings:</h5>
            <p>
              Hotel bookings are made in accordance with the hotel's terms and
              conditions. Special requests are subject to availability and
              cannot be guaranteed.
            </p>
          </div>
          <h3>Liability</h3>

          <div className="list">
            <h5 className="heading">Travel Insurance:</h5>
            <p>
              We recommend that you purchase travel insurance. We are not
              responsible for any losses, damages, or inconveniences during your
              travel.
            </p>
          </div>
          <div className="list">
            <h5 className="heading">Force Majeure:</h5>
            <p>
              We are not liable for any failure or delay in providing services
              due to circumstances beyond our control, such as natural
              disasters, strikes, or government actions.
            </p>
          </div>
          <h3>Privacy</h3>

          <div className="list">
            <h5 className="heading">Data Collection:</h5>
            <p>
              We collect personal information as necessary for booking and
              providing our services. Our [Privacy Policy]{" "}
              <span>
              <Link to="/privacy">
              Privacy Policy
              </Link>
              </span>{" "}
              explains how we collect, use, and protect your information.
            </p>
          </div>
          <h3> Governing Law</h3>

          <div className="list">
            <h5 className="heading">Jurisdiction:</h5>
            <p>
            These Terms are governed by the laws of India. Any disputes arising from these Terms or the services provided will be subject to the exclusive jurisdiction of the courts in Pune, India.
            </p>
          </div>
          <h3> Amendments </h3>

          <div className="list">
            <h5 className="heading">Changes to Terms:</h5>
            <p>
            We reserve the right to update or modify these Terms at any time. The latest version will be available on our website, and continued use of our services constitutes acceptance of the updated Terms.
            </p>
          </div>
          <h3> Contact Us </h3>

          <div className="list">
            <h5 className="heading">Inquiries:</h5>
            <p>
            For any questions or concerns about these Terms, please contact us at info@theindianjourney.com.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
