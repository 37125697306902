import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { FaChevronDown } from "react-icons/fa";
import Popup from "../popup/Popup";
import { Link } from "react-router-dom";
import "./vessains.scss";
import "./themeBased.scss";
import dayjs from "dayjs";
import { GrClose } from "react-icons/gr";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import toast from "react-hot-toast";

const ThemeBased = () => {
  const [depDate, setDepDate] = useState(false);
  const [destinationOpen, setDestinationOpen] = useState(false);
  const [peopleOpen, setPeopleOpen] = useState(false);
  const [selectedDestination, setSelectedDestination] =
    useState("Theme Destination");
  const [selectedPeople, setSelectedPeople] = useState(1);

  const [destination, setDestination] = useState({
    journey: "",
    date: "",
    peoples: {
      adults: "",
      children: "",
      infands: "",
      chooseTravel: {
        economy: "",
        premium: "",
        business: "",
      },
    },
  });

  const destinations = [
    "Study Journey",
    "Sports Journey",
    "Culinary Journey",
    "History And Cultural Journey",
    "Photography Journey",
    "Yoga and Meditation Journey",
    "Acroyoga Journey",
    "Event Journey",
  ];
  const peopleOptions = [1, 2, 3, 4, 5];

  const closeAllDropdowns = () => {
    setDestinationOpen(false);
    setPeopleOpen(false);
  };
  const [trip, setTrip] = useState({
    oneWay: true,
    returnTrip: false,
    multiCity: false,
  });

  const closeAllTrip = () => {
    setTrip({
      oneWay: true,
      returnTrip: true,
      multiCity: true,
    });
  };

  const [flight, setflight] = useState({
    depDate: false,
    travelClass: false,
    returnDate: false,
  });

  const closeAll = () => {
    setflight({
      depDate: false,
      travelClass: false,
      returnDate: false,
    });
  };

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  const formatCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const optionsMonthYear = { month: "short", year: "numeric" };
    const monthYear = new Intl.DateTimeFormat("en-US", optionsMonthYear).format(
      date
    );
    const optionsDayOfWeek = { weekday: "long" };
    const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(
      date
    );
    return `${day} ${monthYear} (${dayOfWeek})`;
  };

  const adults = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const children = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const infants = ["0", "1", "2", "3", "4", "5", "6", "7"];

  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    themename: selectedDestination,
    date_of_travel: formatCurrentDate() || "",
    adults: "1",
    kids_no: "0",
    infants: "0",
  });

  console.log("theme based journey data", formdata);
  // -------------------------------------------------------date functionality-------------------------------------//
  const [selectedDate, setSelectedDate] = useState(null);
  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    if (isNaN(date.getTime()))
      return { day: "", month: "", year: "", weekday: "" };
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const weekday = date.getDay();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return `${day < 10 ? `0${day}` : day} ${months[month]} ${year} (${
      weekdays[weekday]
    })`;
  };

  const handleDateChange = (newDate) => {
    const formattedDate = formatISODate(newDate);
    setSelectedDate(newDate);
    setFormData((prevData) => ({
      ...prevData,
      date_of_travel: formattedDate,
    }));
  };

  const handleSeatClick = (value) => {
    setFormData({ ...formdata, adults: value });
  };

  const handlechildClick = (value) => {
    setFormData({ ...formdata, kids_no: value });
  };

  const handleinfantClick = (value) => {
    setFormData({ ...formdata, infants: value });
  };

  console.log(formdata);

  useEffect(() => {
    setFormData({
      ...formdata,
      themename: selectedDestination,
    });
  }, [selectedDestination]);

  const submitData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}theme-based-journey/`,
        formdata
      );
      toast.success("Message sent !");
      setFormData({
        name: "",
        email: "",
        mobile: "",
        themename: selectedDestination,
        date_of_travel: formatCurrentDate() || "",
        adults: "1",
        kids_no: "0",
        infants: "0",
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="form theme-based-form ">
        <form action="" className="theme-based-form22 form22">
          <div className="middle-form theme-based-middle-form" id="theme-based">
            {/* Theme Destination Field */}
            <div
              className="from from-div theme-based-from"
              onClick={() => {
                closeAllDropdowns();
                setDestinationOpen(!destinationOpen);
              }}
            >
              <h3>{selectedDestination}</h3>

              {destinationOpen && (
                <div className="dropdown theme-based-dropdown">
                  {destinations.map((destination) => (
                    <div
                      className="list-hover"
                      key={destination}
                      onClick={() => {
                        setSelectedDestination(destination);
                        setDestinationOpen(false);
                      }}
                    >
                      {destination}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Date of Travel Field */}
            <div className="from departure " onClick={() => setDepDate(true)}>
              <p className="top-tag">Date of Travel</p>
              <h4>{formdata.date_of_travel}</h4>
              {depDate && (
                <div
                  className="date-div"
                  onMouseLeave={() => setDepDate(false)}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      className="calender"
                      onChange={handleDateChange}
                      value={selectedDate}
                      minDate={dayjs()}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>

            {/* Number of People Field */}
            <div
              className="from travellersclass"
              onClick={() => {
                closeAll();
                setflight((element) => ({
                  ...element,
                  travelClass: true,
                }));
              }}
            >
              <p className="top-tag">
                Number of people{" "}
                <span>
                  <FaChevronDown />
                </span>
              </p>
              <h3 className="date">
                {/* {dateFetch.day} */}{" "}
                {parseInt(formdata.adults) +
                  parseInt(formdata.kids_no) +
                  parseInt(formdata.infants)}
                <span className="month">Travellers</span>
              </h3>
              {flight.travelClass && (
                <div className="travle-class">
                  <div className="taps adults">
                    <div className="top">
                      <h5>ADULTS(12Y +)</h5>
                      <p>on the day of travel</p>
                    </div>
                    <div className="taps-list ">
                      {adults.map((adults, index) => (
                        <span
                          key={index}
                          className={`seat${adults} seat ${
                            formdata.adults == adults ? "active" : ""
                          }`}
                          onClick={() => handleSeatClick(adults)}
                        >
                          {adults === "more than 9" ? "+9" : adults}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="taps children">
                    <div className="top">
                      <h5>CHILDREN(2y - 12y)</h5>
                      <p>on the day of travel</p>
                    </div>
                    <div className="taps-list ">
                      {children.map((kids, index) => (
                        <span
                          key={index}
                          className={`seat${kids} seat ${
                            formdata.kids_no == kids ? "active" : ""
                          }`}
                          onClick={() => handlechildClick(kids)}
                        >
                          {kids === "more than 6" ? "+6" : kids}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="taps infants">
                    <div className="top">
                      <h5>INFANTS (below 2y)</h5>
                      <p>on the day of travel</p>
                    </div>
                    <div className="taps-list ">
                      {infants.map((infants, index) => (
                        <span
                          key={index}
                          className={`seat${infants} seat ${
                            formdata.infants == infants ? "active" : ""
                          }`}
                          onClick={() => handleinfantClick(infants)}
                        >
                          {infants === "more than 6" ? "+6" : infants}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="taps class">
                    <div className=" class">
                      <div className="right">
                        <span
                          className="apply"
                          onClick={(e) => {
                            e.stopPropagation();
                            setflight((element) => ({
                              ...element,
                              travelClass: false,
                            }));
                          }}
                        >
                          Apply
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Link
            style={{ textAlign: "center" }}
            className="btn"
            onClick={toggleModal}
          >
            Enquire Now
          </Link>
        </form>
      </div>

      {modal && (
        <>
          <div className="popup-parent parent">
            <div className="popup-cont container">
              <div className="close-pop" onClick={toggleModal}>
                <GrClose />
              </div>
              <div className="contact-form">
                <h2 className="contact-heading">Enquire Now</h2>

                <div className="input-div">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    onChange={(e) =>
                      setFormData({ ...formdata, name: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email Id "
                    onChange={(e) =>
                      setFormData({ ...formdata, email: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="text"
                    name="Number"
                    placeholder="Mobile Number"
                    onChange={(e) =>
                      setFormData({ ...formdata, mobile: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <textarea
                    name="Message"
                    cols="46"
                    rows="3"
                    placeholder="Message"
                    onChange={(e) =>
                      setFormData({ ...formdata, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="btn-div">
                  <button type="submit" className="btn" onClick={submitData}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ThemeBased;
