import React from "react";
import PageTop from "../comps/PageTop";
import img3 from "../assets/journey/photography_journey.webp";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/photo1.webp";
import Before_Footer from "../comps/Before_footer";
import Pagebreak from "../comps/Pagebreak";

function Photography() {
  return (
    <>
      <PageTop title="Photography Journey" img={img3} />
      <Pagebreak greyBreakPage={true} />
      <ServicePage
        bg="white"
        // title="Photography Journey"
        subdesc="Capture the soul of India through the lens of your camera. Our photography journey offers unique opportunities to capture breathtaking landscapes, vibrant cultures, and timeless moments. From majestic mountains to bustling cities, our curated itinerary will inspire your creativity and leave you with a collection of stunning images to cherish."
        heading="Photography Journey"
        listtitle=""
        imgurl={img1}
        desc="
       Mentored photography tours to amazing locations across India. Combine your twin passions of Travel and Photography to join us on journeys to the best of photography destination across India & many more foreign destinations. Photography tours with The Indian Journey are timed according to the best photography opportunities at different places. We keep adding new exciting locations to our calendar.

We specialize in domestic and international photo travel, and are a full-time 'client first', and 'photography first' photo tour entity with group sizes ranging from 2 to 8 travelers.

"
      />
    </>
  );
}

export default Photography;
