import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import 'swiper/css/pagination';
import Pagebreak from "../comps/Pagebreak";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../styles/Hero.scss";
import axios from "axios";
import { Link } from "react-router-dom";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

export default function Hero(props) {
  const [data, setData] = useState([]);
  const fetchSlides = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}carousel/`);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSlides();
  }, []);
  return (
    <div className="parent hero">
      {props.moboverlay && <div className="hero-overlay"></div>}
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {data &&
          data.map((item, index) => {
            return (
              <SwiperSlide
                className="parent hero-slide"
                style={{ background: `url(${item.image})` }}
              >
                <div className="overlay"></div>
                <div className="slide-content">
                  <h3>{truncateText(item.title, 4)}</h3>
                  <p>{truncateText(item.description, 100)}</p>
                  <Link className="btn" to={item.btn_link}>
                    {item.btn_text}
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        <div className="pgbreak">
          <Pagebreak greyBreakPage={true} />
        </div>
      </Swiper>
    </div>
  );
}
