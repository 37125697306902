import React from "react";
import PageTop from "../comps/PageTop";
import img3 from "../assets/journey/sport_journey.jpg";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/sport1.webp";
import Before_Footer from "../comps/Before_footer";
import Pagebreak from "../comps/Pagebreak";

function Sport() {
  const cheakdata = [
    "India",
    "UK",
    "Spain",
    "Australia",
    "Africa",
    "UAE",
    " Golf Journey",
    "Football matches",
    "FIFA World Cup, etc.",
    "Olympic Games",
    "Cricket Matches",
    "F1 Grand Prix",
    "Biking",
    "Hiking",
  ];
  return (
    <>
      <PageTop title="Sports Journey " img={img3} />
      <Pagebreak 
      greyBreakPage={true}
      />
      <ServicePage bg="white"
        // title="Sports Journey "
        subdesc="Sports Tourism is gaining popularity and becoming a fast-growing sector of the global travel industry. The sports fans are traveling to far places to attend or participate in their favorite sporting event. The increase in sports tournaments and other sporting activities in the world and the growth in multimedia coverage of international sports tournaments have enhanced the popularity of Sports tourism.      "
        indicator="Our Sport Journey"
        listtitle="Journeys we cover Country and Destination"
        imgurl={img1}
        cheakdata={cheakdata}
      />
   
    </>
  );
}

export default Sport;
