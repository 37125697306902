import React from "react";
import PageTop from "../comps/PageTop";
import img3 from "../assets/journey/yoga_meditation_journey.webp";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/yoga1.webp";
import Before_Footer from "../comps/Before_footer";
import Pagebreak from "../comps/Pagebreak";

function Yoga() {
  return (
    <>
      <PageTop title="Yoga and Meditation Journey" img={img3} />
      <Pagebreak greyBreakPage={true} />
      <ServicePage
        bg="white"
        // title="Yoga and Meditation Journey"
        subdesc="Escape the chaos of everyday life and find solace in the quiet depths of your being. Explore the transformative practices of yoga and meditation, and experience the profound impact they can have on your life. Embrace mindfulness to cultivate inner peace and balance, allowing you to connect more deeply with yourself."
        heading="Our Yoga and Meditation Journey"
        listtitle=""
        imgurl={img1}
        desc="This Journey is designed for people who want to connect with their self, bring peace and balance to their life, have a quenched soul and being aligned with nature and the universe. The Indian Journey has ensured to craft this soul-awakening journey such that it becomes one of the most important and – a life-changing journey for all travelers. The tour will have guided assistance to help you in every step of the journeys so that you can relax, learn, contemplate, laugh, meditate and introspect at your Own peace and luxury."

      />
    </>
  );
}

export default Yoga;
