import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../styles/holiday.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { HiMinusSm } from "react-icons/hi";
import { HiOutlinePlusSm } from "react-icons/hi";
import { RiArrowLeftSLine } from "react-icons/ri";
import ProgressBar from "../../comps/ProgressBar";
import BudgetProgressBar from "../../comps/BudgetBar";
import { IoMdStar } from "react-icons/io";
import Complete from "../../comps/Autocomplete";
import dayjs from "dayjs";
import { GrClose } from "react-icons/gr";
import toast from "react-hot-toast";

const HolidayPackages = (props) => {
  const [roomCount, setRoomCount] = useState({
    adultRoom: [],
    childRoom: [],
    room: 1,
  });
  const [selectedstay,setselectedstay] = useState("< 5")
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
    from_place: "Pune",
    to: "Mumbai",
    departure_date: "",
    selected_rooms: roomCount,
    duration_of_stay: selectedstay,
    flight: "With Flight",
    per_person_budget: props.getrange,
    hotel_category: "3 star",
  });
  console.log("my data_", roomCount);
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      selected_rooms: roomCount,
    }));
  }, [roomCount]);
  useEffect(() => {
    setFormData({
      ...formData,
      per_person_budget: props.getrange,
    });
  }, [props.getrange]);

  const [toggle, settoggle] = useState(false);
  const [appendRooms, setAppendRooms] = useState(1);
  const [adultroomCounts, setadultRoomCounts] = useState([1]);
  const [childroomCounts, setchildRoomCounts] = useState([1]);
  const [holidayState, setHolidayState] = useState({
    fromState: false,
    toState: false,
    depDate: false,
    guestRooms: false,
    filters: false,
  });

  const closeAll = () => {
    setHolidayState({
      toState: false,
      fromState: false,
      depDate: false,
      guestRooms: false,
      filters: false,
    });
    console.log("....close");
  };

  const handleClick = (key) => {
    closeAll();
    setHolidayState((prevState) => ({
      ...prevState,
      [key]: true,
    }));
  };

  const addRoom = () => {
    setAppendRooms((prevAppendRooms) => prevAppendRooms + 1);
    setadultRoomCounts((prevRoomCounts) => [...prevRoomCounts, 2]);
    setchildRoomCounts((prevRoomCounts) => [...prevRoomCounts, 2]);
  };

  const removeRoom = (index) => {
    if (appendRooms > 1) {
      setAppendRooms((prevAppendRooms) => prevAppendRooms - 1);
      setadultRoomCounts((prevRoomCounts) =>
        prevRoomCounts.filter((_, i) => i !== index)
      );
      setchildRoomCounts((prevRoomCounts) =>
        prevRoomCounts.filter((_, i) => i !== index)
      );
    }
  };

  const updateRoomCount = (index, increment) => {
    setadultRoomCounts((prevRoomCounts) =>
      prevRoomCounts.map((count, i) =>
        i === index ? Math.max(count + increment, 1) : count
      )
    );
  };
  const updatechildRoomCount = (index, increment) => {
    setchildRoomCounts((prevRoomCounts) =>
      prevRoomCounts.map((count, i) =>
        i === index ? Math.max(count + increment, 1) : count
      )
    );
  };

  //
  console.log(roomCount);
  const [fromdetails, setFromdetails] = useState({
    city: "",
    code: "",
    name: "",
    country: "",
  });
  const [todetails, setTodetails] = useState({
    city: "",
    code: "",
    name: "",
    country: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      selected_rooms: roomCount,
    });
  }, [roomCount]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [holidayForm, setHolidayForm] = useState({
    from: "",
    to: "",
    departure_date: "",
    rooms: {
      room: {
        number: "",
        adult: "",
        children: "",
      },
    },
    filterData: {
      durationInNights: "",
      travelsInfo: "",
      budget: "",
      hotelCategory: "",
    },
  });
  const handleClickRooms = (e) => {
    e.stopPropagation();
    setRoomCount({
      adultRoom: adultroomCounts,
      childRoom: childroomCounts,
      room: appendRooms,
    });
    setHolidayState({ ...holidayState, guestRooms: false });
    setHolidayForm((prevData) => ({
      ...prevData,
      rooms: adultroomCounts.map((adultCount, index) => ({
        room: {
          number: appendRooms,
          adult: adultCount,
          children: childroomCounts[index],
        },
      })),
    }));
  };
  useEffect(() => {
    setFormData({
      ...formData,
      departure_date: holidayForm.departure_date,
    });
  }, [holidayForm]);

  // handle chnage destination
  const handleDestinationSelect = (value) => {
    setTodetails({
      city: value.city,
      code: value.code,
      name: value.name,
      country: value.country,
    });
    setHolidayForm((prevData) => ({
      ...prevData,
      to: value.name,
    }));

    setFormData({
      ...formData,
      to: value.city + " " + value.country,
    });
    closeAll();
  };

  // handle chnage departure
  const handleDepartureSelect = (value) => {
    setFromdetails({
      city: value.city,
      code: value.code,
      name: value.name,
      country: value.country,
    });
    setHolidayForm((prevData) => ({
      ...prevData,
      from: value.name,
    }));
    setFormData({
      ...formData,
      from_place: value.city + " " + value.country,
    });
    closeAll();
  };

  //
  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    if (isNaN(date.getTime()))
      return { day: "", month: "", year: "", weekday: "" };
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const weekday = date.getDay();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return `${day < 10 ? `0${day}` : day} ${months[month]} ${year} (${
      weekdays[weekday]
    })`;
  };
  const handleDateChange = (newDate) => {
    const formattedDate = formatISODate(newDate);
    setSelectedDate(newDate);

    setHolidayForm((prevData) => ({
      ...prevData,
      departure_date: formattedDate,
    }));
    closeAll();
  };

  console.log("applyroom", roomCount);

  const handleformSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}holiday-packages/`,
        formData
      );
      toast.success("Form Submitted!");
      settoggle(false);
    } catch (err) {
      console.log(err);
    }
  };

  console.log("___formdata-------------------------------", formData);

  const stayoptions = ["< 5", "6", "7", "8", "> 9"];

  return (
    <>
      <div className="form">
        <form action="">
          <div className="bottom-form">
            <div
              div
              className="input fromcity"
              onClick={() => handleClick("fromState")}
            >
              <p>From City</p>
              <div className="city">
                <h4> {fromdetails?.city || "Pune"} </h4>
                <p> {fromdetails?.country || "India"} </p>
              </div>

              {holidayState.fromState && (
                <div
                  className="autocomplete"
                  onBlur={() =>
                    setHolidayState((prev) => ({
                      ...prev,
                      fromState: false,
                      depDate: false,
                      guestRooms: false,
                      filters: false,
                    }))
                  }
                >
                  <Complete
                    placeholder="Search for departure airport"
                    onSelectAirport={handleDepartureSelect}
                    close={() =>
                      setHolidayState({ ...holidayState, fromState: false })
                    }
                  />
                </div>
              )}
            </div>
            <div
              className="input tocity"
              onClick={() => handleClick("toState")}
            >
              <p>To City</p>
              <div className="city">
                <h4>{todetails?.city || "Mumbai"}</h4>
                <p>{todetails?.country || "India"}</p>
              </div>
              {holidayState.toState && (
                <div
                  className="autocomplete"
                  onBlur={() =>
                    setHolidayState((prev) => ({
                      ...prev,
                      toState: false,
                    }))
                  }
                >
                  <Complete
                    placeholder="Search for departure airport"
                    onSelectAirport={handleDestinationSelect}
                    close={() =>
                      setHolidayState({ ...holidayState, toState: false })
                    }
                  />
                </div>
              )}
            </div>
            <div
              className="input depDate"
              onClick={() => handleClick("depDate")}
            >
              <div className="dep">
                <p>Departure date</p>
                <span
                  className={holidayState.depDate ? "rotateIcon icon" : "icon"}
                >
                  <MdKeyboardArrowDown />
                </span>
              </div>
              <h4>{holidayForm?.departure_date || "Selected Date"}</h4>
              {holidayState.depDate && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    onMouseLeave={closeAll}
                    className="calender"
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={dayjs()}
                  />
                </LocalizationProvider>
              )}
            </div>
            <div
              className="input room-guest"
              onClick={() => handleClick("guestRooms")}
            >
              <div className="dep">
                <p className="roomsguests">Rooms & Guests</p>
                <span
                  className={
                    holidayState.guestRooms ? "rotateIcon icon" : "icon"
                  }
                >
                  <MdKeyboardArrowDown />
                </span>
              </div>
              <h4>{roomCount && roomCount.room} rooms</h4>

              {holidayState.guestRooms && (
                <div className="room-popup" onMouseLeave={closeAll}>
                  {Array.from({ length: appendRooms }, (_, index) => (
                    <>
                      <div className="top" key={index}>
                        <p>Room {index + 1}</p>

                        {index >= 1 && (
                          <div className="remove" onClick={removeRoom}>
                            Remove
                          </div>
                        )}
                      </div>

                      <div className="middle">
                        <div className="left">
                          <p>
                            <strong>Adults- </strong>
                            Above 12 Years
                          </p>
                          <div className="room-numbers">
                            <span onClick={() => updateRoomCount(index, -1)}>
                              <HiMinusSm />
                            </span>
                            <p> {adultroomCounts[index]} </p>
                            <span onClick={() => updateRoomCount(index, 1)}>
                              <HiOutlinePlusSm />
                            </span>
                          </div>
                        </div>
                        <div className="right left">
                          <p>
                            <strong>Children- </strong>
                            Below 12 Years
                          </p>
                          <div className="room-numbers">
                            <span
                              onClick={() => updatechildRoomCount(index, -1)}
                            >
                              <HiMinusSm />
                            </span>
                            <p> {childroomCounts[index]} </p>
                            <span
                              onClick={() => updatechildRoomCount(index, 1)}
                            >
                              <HiOutlinePlusSm />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div
                    className="bottom"
                    style={{ display: appendRooms > 3 ? "none" : "flex" }}
                  >
                    <div className="add-another-room" onClick={addRoom}>
                      Add Another Room
                    </div>
                    <div
                      className="add-another-room"
                      onClick={handleClickRooms}
                    >
                      Apply
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="input filters"
              onClick={() => handleClick("filters")}
            >
              <div className="dep">
                <p className="">filters</p>
                <span
                  className={holidayState.filters ? "rotateIcon icon" : "icon"}
                >
                  <MdKeyboardArrowDown />
                </span>
              </div>
              <div className="holiday-wrapper">
              <h4>{selectedstay} <span>(Nights)</span></h4>
              <p>{formData.flight}</p>
              <p>{formData.hotel_category}</p>
              </div>

              {holidayState.filters && (
                <div className="filter-popup">
                  <div className="top">
                    <div className="first-bar">
                      <div className="tag-head">Stay (Nights)</div>
                      {/* <ProgressBar
                        setstay={props.setstay}
                        getstay={props.getstay}
                      /> */}

                      <div className="stayoption">
                        {stayoptions &&
                          stayoptions.map((data) => {
                            return (
                              <button className={`tap-new ${selectedstay === data ? "active" : ""}`} type="button" onClick={()=>setselectedstay(data)}>
                                {data}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                    <div className="second-bar">
                      <div className="tag">Flights</div>
                      <div className="taps">
                        <div
                          className={`tap1 ${
                            formData.flight == "With Flight" ? "select" : ""
                          }`}
                          onClick={() =>
                            setFormData({ ...formData, flight: "With Flight" })
                          }
                        >
                          With Flight
                        </div>
                        <div
                          className={`tap1 ${
                            formData.flight == "Without Flight" ? "select" : ""
                          }`}
                          onClick={() =>
                            setFormData({
                              ...formData,
                              flight: "Without Flight",
                            })
                          }
                        >
                          Without Flight
                        </div>
                      </div>
                    </div>

                    <div className="third-bar">
                      <BudgetProgressBar
                        setrange={props.setrange}
                        getrange={props.getrange}
                      />
                    </div>

                    <div className="fourth-bar second-bar">
                      <div className="tag">Hotel Category</div>

                      <div className="taps">
                        <div
                          className={`tap1 ${
                            formData.hotel_category === "3 star" ? "select" : ""
                          }`} // Corrected "3 start" to "3 star"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              hotel_category: "3 star",
                            })
                          }
                        >
                          3{" "}
                          <span>
                            <IoMdStar />
                          </span>
                        </div>
                        <div
                          className={`tap1 ${
                            formData.hotel_category === "4 star" ? "select" : ""
                          }`} // Added conditional check for "4 star"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              hotel_category: "4 star",
                            })
                          }
                        >
                          4{" "}
                          <span>
                            <IoMdStar />
                          </span>
                        </div>
                        <div
                          className={`tap1 ${
                            formData.hotel_category === "5 star" ? "select" : ""
                          }`} // Added conditional check for "5 star"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              hotel_category: "5 star",
                            })
                          }
                        >
                          5{" "}
                          <span>
                            <IoMdStar />
                          </span>
                        </div>
                        <div
                          className={`tap1 ${
                            formData.hotel_category === "7 star" ? "select" : ""
                          }`} // Added conditional check for "7 star"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              hotel_category: "7 star",
                            })
                          }
                        >
                          7{" "}
                          <span>
                            <IoMdStar />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div
                      className="btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHolidayState({ ...holidayState, filters: false });
                      }}
                    >
                      Apply
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="home-button">
            <button
              className="btn"
              type="button"
              onClick={() => settoggle(true)}
            >
              Enquire Now
            </button>
          </div>
        </form>
      </div>

      {toggle && (
        <>
          <div className="popup-parent parent">
            <div className="popup-cont container">
              <div className="close-pop" onClick={() => settoggle(false)}>
                <GrClose />
              </div>
              <div className="contact-form">
                <h2 className="contact-heading">Enquire Now</h2>

                <div className="input-div">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email Id "
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="text"
                    name="Number"
                    placeholder="Mobile Number"
                    onChange={(e) =>
                      setFormData({ ...formData, contact: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <textarea
                    name="Message"
                    cols="46"
                    rows="3"
                    placeholder="Message"
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="btn-div">
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleformSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HolidayPackages;
