import React from "react";
import "../styles/Img.scss";

function Img(props) {
  const handlePreview = () => {
    const link = document.createElement('a');
    link.href = props.imgurl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };
  // const handleDownload = () => {
  //   const link = document.createElement('a');
  //   link.href = props.imgurl; // Access the image URL from props
  //   link.download = `image_${Date.now()}`; // Generate a unique name based on the current timestamp
  //   link.click();
  // };
  return (
    <div className="img-parent">

      <img src={props.imgurl} alt="image"/>
      
      <div className="overlay">
        <button className="btn" onClick={handlePreview}>Download</button>
      </div>
    </div>
  );
}

export default Img;