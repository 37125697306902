import React, { useEffect, useState } from "react";
import { country } from "../../static/Country";
import axios from "axios";
import { AutoComplete, Calendar, DatePicker, notification } from "antd";
import { IoIosSwap, IoMdVolumeHigh } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Complete from "../../comps/Autocomplete";
import { GrClose } from "react-icons/gr";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import airports from "../../static/airports.json";
const Flight = () => {
  const [adult, setAdult] = useState(1);
  const [kids, setkids] = useState(0);

  const [fromdetails, setFromdetails] = useState({
    city: "",
    code: "",
    name: "",
    country: "",
  });

  const [todetails, setTodetails] = useState({
    city: "",
    code: "",
    name: "",
    country: "",
  });
  const [trip, setTrip] = useState({
    oneWay: true,
    returnTrip: false,
  });

  const formatCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const optionsMonthYear = { month: "short", year: "numeric" };
    const monthYear = new Intl.DateTimeFormat("en-US", optionsMonthYear).format(
      date
    );
    const optionsDayOfWeek = { weekday: "long" };
    const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(
      date
    );
    return `${day} ${monthYear} (${dayOfWeek})`;
  };

  console.log("round trip", trip.returnTrip);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    from_place: fromdetails.name || "Indira Gandhi International Airport",
    to_place: todetails.name || "Chhatrapati Shivaji International Airport",
    adults_no: "1",
    kids_no: "0",
    infants: "0",
    travel_class: "Economy/Premium Economy",
    travel_type: trip.returnTrip ? "Round Trip " : "one way",
    departure_date: formatCurrentDate() || "",
    return_date: formatCurrentDate() || "",
  });
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      travel_type: trip.returnTrip ? "Round Trip" : "one way",
      return_date: trip.returnTrip ? null : "",
    }));
  }, [trip.returnTrip]);
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      adults_no: adult,
      kids_no: kids,
    }));
  }, [adult, kids]);

  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const closeAllTrip = () => {
    setTrip({
      oneWay: true,
      returnTrip: true,
    });
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedReturnDate, setSelectedReturnDate] = useState(null);
  const [dateFetch, setDateFetch] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [autocomplete, setAutocomplete] = useState({
    from: false,
    to: false,
  });

  const close = () => {
    setAutocomplete({
      from: false,
      to: false,
    });
  };

  const handleDateChange = (newDate) => {
    console.log("====dep", newDate);
    const formattedDate = formatISODate(newDate);
    setSelectedDate(newDate);
    setFormData((prevData) => ({
      ...prevData,
      departure_date: formattedDate,
    }));
    // closeAll();
  };
  const handleReturnDateChange = (newDate) => {
    console.log("====return", newDate);
    const formattedDate = formatISODate(newDate);
    setSelectedReturnDate(newDate);
    setFormData((prevData) => ({
      ...prevData,
      return_date: formattedDate,
    }));
    // closeAll();
  };

  const [flight, setflight] = useState({
    depDate: false,
    travelClass: false,
    returnDate: false,
  });

  const closeAll = () => {
    setflight({
      depDate: false,
      travelClass: false,
      returnDate: false,
    });
  };

  const handleDepartureSelect = (value) => {
    setFromdetails({
      city: value.city,
      code: value.code,
      name: value.name,
      country: value.country,
    });
    setFormData((prevData) => ({
      ...prevData,
      from_place: value.name,
    }));
    close();
  };

  const handleArrivalSelect = (value) => {
    setTodetails({
      city: value.city,
      code: value.code,
      name: value.name,
      country: value.country,
    });

    setFormData((prevData) => ({
      ...prevData,
      to_place: value.name,
    }));
    close();
  };

  const changeData = () => {
    setFromdetails((prevFrom) => {
      const updatedFrom = { ...prevFrom };
      setFromdetails({
        city: todetails.city || "New Delhi",
        code: todetails.code || "DEL",
        name: todetails.name || "Indira Gandhi International Airport",
        country: todetails.country || "India",
      });
      setTodetails({
        city: updatedFrom.city || "Mumbai",
        code: updatedFrom.code || "BOM",
        name: updatedFrom.name || "Chhatrapati Shivaji International Airport",
        country: updatedFrom.country || "India",
      });
      setFormData((prevData) => ({
        ...prevData,
        from_place: todetails.name,
        to_place: updatedFrom.name,
      }));
      return updatedFrom;
    });
  };

  const [departuredate, setDeparturedate] = useState({
    day: "",
    month: "",
    year: "",
    weekday: "",
  });

  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    if (isNaN(date.getTime()))
      return { day: "", month: "", year: "", weekday: "" };
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const weekday = date.getDay();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return `${day < 10 ? `0${day}` : day} ${months[month]} ${year} (${
      weekdays[weekday]
    })`;
  };

  console.log(formData.departure_date);

  const adults = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const children = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const infants = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const travelclass = [
    "Economy/Premium Economy",
    "Premium Economy",
    "Business",
  ];

  const handleSeatClick = (value) => {
   
    setFormData({ ...formData, adults_no: value });
  };

  const handlechildClick = (value) => {

    setFormData({ ...formData, kids_no: value });
  };

  const handleinfantClick = (value) => {
    setFormData({ ...formData, infants: value });
  };
  const handleclassClick = (value) => {
    setFormData({ ...formData, travel_class: value });
  };

  const [toggle, settoggle] = useState(false);

  const handleformSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}flight-booking/`,
        formData
      );
      settoggle(false);
      toast.success("Form submitted.");
    } catch (error) {
      console.log(error);
    }
  };
  console.log("____my form data____", formData);
  
  return (
    <>
      <div className="form">
        <form action="" className="form22">
          <div className="top">
            <div className="left">
              <div
                className={trip.oneWay ? "trip1  active" : "trip1"}
                onClick={() => {
                  setTrip((element) => ({
                    ...element,
                    oneWay: true,
                    returnTrip: false,
                  }));
                }}
              >
                <div className="circle">{trip.oneWay && <TiTick />}</div>
                <p>One Way</p>
              </div>
              <div
                className={trip.returnTrip ? "trip1  active" : "trip1"}
                onClick={() => {
                  closeAllTrip();
                  setTrip((element) => ({
                    ...element,
                    returnTrip: true,
                    oneWay: false,
                  }));
                }}
              >
                <div className="circle">{trip.returnTrip && <TiTick />}</div>
                <p>Round Trip</p>
              </div>
            </div>
          </div>

          <div className="middle-form" id="oneway">
            {(trip.oneWay || trip.returnTrip) && (
              <div className="from from-div">
                <p className="top-tag">From</p>
                {/* from place */}
                <h3
                  onClick={() =>
                    setAutocomplete((prev) => ({
                      ...prev,
                      from: true,
                      to: false,
                    }))
                  }
                >
                  {fromdetails.code ? fromdetails.code : "DEL"}
                </h3>
                <p
                  className="place-tag align-left"
                  onClick={() =>
                    setAutocomplete((prev) => ({
                      ...prev,
                      from: true,
                      to: false,
                    }))
                  }
                >
                  {fromdetails?.city || "New Delhi"},{" "}
                  {fromdetails?.name || "Indira Gandhi International Airport"}{" "}
                  {fromdetails?.country || "India"}
                </p>

                <div className="swipeIcon" onClick={changeData}>
                  <IoIosSwap />
                </div>

                {autocomplete.from && (
                  <div
                    className="autocomplete"
                    onBlur={() =>
                      setAutocomplete((prev) => ({
                        ...prev,
                        from: false,
                        to: false,
                      }))
                    }
                  >
                    <Complete
                      placeholder="Search for departure airport"
                      onSelectAirport={handleDepartureSelect}
                      close={close}
                      airports={airports}
                    />
                  </div>
                )}
              </div>
            )}

            {(trip.oneWay || trip.returnTrip) && (
              <div className="from to to-div">
                {/* to */}

                <p className="top-tag">To</p>
                <h3
                  onClick={() =>
                    setAutocomplete((prev) => ({
                      ...prev,
                      to: true,
                      from: false,
                    }))
                  }
                >
                  {todetails.code ? todetails.code : "BOM"}
                </h3>
                <p
                  className="place-tag align-left"
                  onClick={() =>
                    setAutocomplete((prev) => ({
                      ...prev,
                      to: true,
                      from: false,
                    }))
                  }
                >
                  {todetails?.city || "Mumbai"},{" "}
                  {todetails?.name ||
                    "Chhatrapati Shivaji International Airport"}{" "}
                  {todetails?.country || "India"}
                </p>

                {autocomplete.to && (
                  <div
                    className="autocomplete"
                    onBlur={() =>
                      setAutocomplete((prev) => ({
                        ...prev,
                        from: false,
                        to: false,
                      }))
                    }
                  >
                    <Complete
                      placeholder="Search for destination airport"
                      onSelectAirport={handleArrivalSelect}
                      close={close}
                      airports={airports}
                    />
                  </div>
                )}
              </div>
            )}
            {(trip.oneWay || trip.returnTrip) && (
              <div
                className="from departure"
                onClick={() => {
                  // closeAll();
                  setflight((prev) => ({
                    ...prev,
                    depDate: true,
                  }));
                }}
                onMouseLeave={() => {
                  setflight((prev) => ({
                    ...prev,
                    depDate: false,
                  }));
                }}
              >
                <p className="top-tag">
                  Departure{" "}
                  <span>
                    <FaChevronDown />
                  </span>
                </p>
                <h4>{formData.departure_date}</h4>
                {flight.depDate && (
                  <div
                    className="calender-box"
         
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        className="calender"
                        onChange={handleDateChange}
                        value={selectedDate}
                        minDate={dayjs()}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            )}

            {(trip.oneWay || trip.returnTrip) && (
              <div
                className="from departure"
                onClick={() => {
                  closeAll();
                  setflight((element) => ({
                    ...element,
                    returnDate: true,
                  }));
                }}
                onMouseLeave={() => {
                  setflight((prev) => ({
                    ...prev,
                    returnDate: false,
                  }));
                }}
              >
                {!trip.returnTrip ? (
                  <p
                    className="tripInfo"
                    onClick={() => {
                      closeAllTrip();
                      setTrip((element) => ({
                        ...element,
                        returnTrip: true,
                        oneWay: false,
                      }));
                    }}
                  >
                    Tap to add a return date for bigger discounts
                  </p>
                ) : (
                  <>
                    <p className="top-tag">
                      Return{" "}
                      <span>
                        <FaChevronDown />
                      </span>
                    </p>
                    <h4>{formData.return_date || formData.departure_date}</h4>
                  </>
                )}
                {flight.returnDate && (
                  <div
                    className="calender-box"
                   
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        className="calender"
                        onChange={handleReturnDateChange}
                        value={selectedReturnDate}
                        minDate={dayjs()}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            )}
            {(trip.oneWay || trip.returnTrip) && (
              <div
                className="from travellersclass"
                onClick={() => {
                  closeAll();
                  setflight((element) => ({
                    ...element,
                    travelClass: true,
                  }));
                }}
                onMouseLeave={() => {
                  setflight((element) => ({
                    ...element,
                    travelClass: false,
                  }));
                }}
              >
                <p className="top-tag">
                  Traveller & Class{" "}
                  <span>
                    <FaChevronDown />
                  </span>
                </p>
                <h3 className="date">
                  {/* {dateFetch.day} */}
                  {parseInt(formData.adults_no) +
                    parseInt(formData.kids_no) +
                    parseInt(formData.infants)}
                  <span className="month">Travellers</span>
                </h3>
                <p className="place-tag">{formData.travel_class}</p>

                {flight.travelClass && (
                  <div className="travle-class">
                    <div className="taps adults">
                      <div className="top">
                        <h5>ADULTS(12Y +)</h5>
                        <p>on the day of travel</p>
                      </div>
                      <div className="taps-list ">
                        {adults.map((adults, index) => (
                          <span
                            key={index}
                            className={`seat${adults} seat ${
                              formData.adults_no == adults ? "active" : ""
                            }`}
                            onClick={() => handleSeatClick(adults)}
                          >
                            {adults === "more than 9" ? "+9" : adults}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="taps children">
                      <div className="top">
                        <h5>CHILDREN(2y - 12y)</h5>
                        <p>on the day of travel</p>
                      </div>
                      <div className="taps-list ">
                        {children.map((kids, index) => (
                          <span
                            key={index}
                            className={`seat${kids} seat ${
                              formData.kids_no == kids ? "active" : ""
                            }`}
                            onClick={() => handlechildClick(kids)}
                          >
                            {kids === "more than 6" ? "+6" : kids}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="taps infants">
                      <div className="top">
                        <h5>INFANTS (below 2y)</h5>
                        <p>on the day of travel</p>
                      </div>
                      <div className="taps-list ">
                        {infants.map((infants, index) => (
                          <span
                            key={index}
                            className={`seat${infants} seat ${
                              formData.infants == infants ? "active" : ""
                            }`}
                            onClick={() => handleinfantClick(infants)}
                          >
                            {infants === "more than 6" ? "+6" : infants}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="taps class">
                      <div className="top">
                        <h5>CHOOSE TRAVEL CLASS</h5>
                      </div>
                      <div className=" classes class">
                        <div className="left">
                          {travelclass.map((data, index) => (
                            <span
                              className={`economybtn ${
                                formData.travel_class === data ? "active" : ""
                              }`}
                              onClick={() => handleclassClick(data)}
                            >
                              {data}
                            </span>
                          ))}
                        </div>
                        <div className="right">
                          <span
                            className="apply"
                            onClick={(e) => {
                              e.stopPropagation();
                              setflight((element) => ({
                                ...element,
                                travelClass: false,
                              }));
                            }}
                          >
                            Apply
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="home-button">
            <button
              className="btn"
              type="button"
              onClick={() => settoggle(true)}
            >
              Enquire Now
            </button>
          </div>
        </form>
      </div>

      {/* contact form */}
      {toggle && (
        <>
          <div className="popup-parent parent">
            <div className="popup-cont container">
              <div className="close-pop" onClick={() => settoggle(false)}>
                <GrClose />
              </div>
              <div className="contact-form">
                <h2 className="contact-heading">Enquire Now</h2>

                <div className="input-div">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email Id "
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="text"
                    name="Number"
                    placeholder="Mobile Number"
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <textarea
                    name="Message"
                    cols="46"
                    rows="3"
                    placeholder="Message"
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="btn-div">
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleformSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Flight;
