import React from "react";
import PageTop from "../comps/PageTop";
import img3 from "../assets/journey/acroyoga_journey.webp";
import ServicePage from "../comps/ServicePage";
import img1 from "../assets/service/acroyoga1.png";
import Before_Footer from "../comps/Before_footer";
import Pagebreak from "../comps/Pagebreak";

function Acroyoga() {
  return (
    <>
      <PageTop title="Acroyoga Journey" img={img3} />
      <Pagebreak greyBreakPage={true} />
      <ServicePage
        bg="white"
        // title="Acroyoga Journey"
        subdesc="Ever dreamed of combining yoga, acrobatics, and travel into one unforgettable adventure? Our AcroYoga Journey in India offers the perfect opportunity to do just that. Immerse yourself in the beauty of this ancient land, exploring its vibrant cities, serene temples, and breathtaking natural landscapes. As you practice AcroYoga, you'll not only cultivate strength, flexibility, and a more profound sense of connection, but you'll also gain a newfound appreciation for India's rich culture and traditions. Whether you're a seasoned yogi or a complete beginner, our AcroYoga Journey is designed to provide a transformative and unforgettable experience."
        heading="Our Acroyoga Journey"
        listtitle=""
        imgurl={img1}
        desc="AcroYoga Journey is our niche service under Yogis Odyssey. We are currently the only company combining this yoga form with the leisure of travelling to India. AcroYoga blends the wisdom of yoga, the dynamic power of acrobatics, and the loving kindness of healing arts. Jenny Sauer-Klein and Jason Nemer co-founded the practice in 2003, which has since touched the lives of hundreds of thousands of people worldwide. With benefits spanning the realms of mind, body, and spirit, AcroYoga is well worth looking into.
"
      />
    </>
  );
}

export default Acroyoga;
