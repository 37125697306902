import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'antd';
import citycountries from '../static/citycountries.json';
const CityComplete = ({ placeholder, onSelectLocation, close }) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const autoCompleteRef = useRef(null);

  // Flatten the JSON into a list of options
  const getAllLocations = () => {
    return Object.keys(citycountries).reduce((acc, country) => {
      citycountries[country].forEach(city => {
        acc.push({
          value: `${city}, ${country}`,
          label: (
            <div>
              <strong>{city}</strong> ({country})
            </div>
          ),
        });
      });
      return acc;
    }, []);
  };

  useEffect(() => {
    // Set default options on component mount
    setOptions(getAllLocations());

    // Focus the input field when the component mounts
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, []);

  const onSearch = (searchText) => {
    if (!searchText) {
      setOptions(getAllLocations()); // Reset to default options when search text is empty
      return;
    }

    const filteredOptions = getAllLocations().filter((option) =>
      option.value.toLowerCase().includes(searchText.toLowerCase())
    );

    setOptions(filteredOptions);
  };

  const onSelect = (value, option) => {
    const [city, country] = value.split(', ');
    const selectedLocation = { country, city };
    onSelectLocation(selectedLocation);
    close();
    console.log('Selected:', selectedLocation);
  };

  const onChange = (data) => {
    setValue(data);
  };
  
  return (
    <AutoComplete
      ref={autoCompleteRef}  // Use ref to access the AutoComplete component
      value={value}
      options={options}
      style={{ width: 300 }}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChange}
      placeholder={placeholder}
      open={true}  // Ensure the dropdown is open when the component is rendered
    />
  );
};

export default CityComplete;
