import React, { useEffect, useState } from "react";
import "../styles/Home.scss";
import FeatureSection from "../comps/FeatureSection";
import Hero from "../comps/Hero";
import { FaPhone, FaPhoneFlip } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { notification } from "antd";
import { getDatabase, ref, set, push } from "firebase/database";
import { app } from "../Firebase";
import Img_card from "../comps/Img_card";
//swiper
// import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Before_Footer from "../comps/Before_footer";
import SectionIndicator from "../comps/SectionIndicator";
import axios from "axios";
import { Link } from "react-router-dom";
import Imageswiper from "../comps/Imageswiper";
import { MdLocalPhone } from "react-icons/md";
// import { Link } from 'react-router-dom';
import Popup from "../pages/popup/Popup"
function Home(props) {
  const [api, contextHolder] = notification.useNotification();
  const [contactDetail, setContactDetail] = useState({
    name: "",
    email: "",
    mobile_number: "",
    date: "",
  });

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}quick-enquiry/`,
        contactDetail
      );
      setContactDetail({
        name: "",
        email: "",
        mobile_number: "",
        date: "",
      });

      const openNotificationWithIcon = () => {
        api.success({
          message: "Message sent successfully!",
          description: "Thank you for contacting us",
        });
      };
      openNotificationWithIcon();
    } catch (error) {
      console.log(error);
    }
  };
  const [eventData, setEventData] = useState([]);
  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}events/`);
      setEventData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onchangeForm = (e, Name) => {
    const { value } = e.target;
    setContactDetail((contactDetail) => ({
      ...contactDetail,
      [Name]: value,
    }));
  };

  // const homePageData = [
  //   {
  //     indicator: "upcoming events",
  //     heading: "Holi- Festival of colors",
  //     bg: "",
  //     direction: "row",
  //     btntext: "Plan Your Trip",
  //     btnlink: "/",
  //     imghigh: "India",
  //     imgheading: "Holi Festival in vrindavan",
  //     imgurl:
  //       "https://images.pexels.com/photos/974320/pexels-photo-974320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere odit magni eum officiis et fugiat. Exercitationem necessitatibus placeat amet consequatur, rem culpa aliquid dicta quas temporibus maxime dignissimos magni fuga? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facere odit magni eum officiis et fugiat. Exercitationem necessitatibus placeat amet consequatur, rem culpa aliquid dicta quas temporibus maxime dignissimos magni fuga?",
  //   },
  // ];
  const lastObject = eventData[eventData.length - 1];
  const homePageData = lastObject
    ? [
      {
        heading: lastObject.title,
        btntext: lastObject.btn_text,
        btnlink: lastObject.btn_link,
        imghigh: lastObject.country,
        imgheading: lastObject.image_text,
        imgurl: lastObject.image,
        desc: lastObject.description,
      },
    ]
    : [];

  console.log(homePageData);

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
  
    
      <Hero />
      {/* <PageTop title="Home Page" img={img} /> */}

      {/* -------------------------------------start form-----------------------------  */}
      {/* <div className="form-parent parent">
        <div className="form-container cont">
          
          {contextHolder}
          <form action="#" onSubmit={formSubmit}>
            <div className="form-input">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Enter Name"
                name="name"
                onChange={(e) => onchangeForm(e, "name")}
                required={true}
                value={contactDetail.name}
              ></input>
            </div>
            <div className="form-input">
              <label>Email</label>
              <input
                required={true}
                type="mail"
                placeholder="Enter Email"
                name="email"
                value={contactDetail.email}
                onChange={(e) => onchangeForm(e, "email")}
              ></input>
            </div>
            <div className="form-input">
              <label>Mobile Number</label>
              <input
                required={true}
                type="text"
                placeholder=" Enter Mobile No."
                name="mobile_number"
                value={contactDetail.mobile_number}
                onChange={(e) => onchangeForm(e, "mobile_number")}
              ></input>
            </div>
            <div className="form-input">
              <label>Select Date</label>
              <input
                required={true}
                type="date"
                placeholder="DD/MM/YYYY"
                name="date"
                value={contactDetail.date}
                className="date-input"
                onChange={(e) => onchangeForm(e, "date")}
              ></input>
            </div>
            <button type="submit" className="btn">
              Submit
            </button>
          </form>
        </div>
      </div> */}
      {/* -------------------------------------End form-----------------------------  */}
      {homePageData &&
        homePageData.map((data) => {
          return (
            <FeatureSection
              indicator="upcoming events"
              heading={data.heading}
              desc={data.desc}
              bg=""
              direction="row"
              btntext={data.btntext}
              btnlink={data.btnlink}
              imghigh={data.imghigh}
              imgheading={data.imgheading}
              imgurl={data.imgurl}
            />
          );
        })}

      {/* ---------------------------Home Services-------------------------- */}
      <div className="home-service-section parent">
        <div className="content-box">
          <SectionIndicator indicator=" Our Services " />
          <h2> What We Offer </h2>
        </div>
        <div className="service-section-home-cont cont">
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            navigation={true}
            // pagination={{
            //   clickable: true,
            // }}
            breakpoints={{
              350: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              728: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="home-card-box">
                <div className="img-box img1 bg-img-cover">
                </div>
                <div className="card-content">
                  <h3>Theme-Based Journeys</h3>
                  <p>
                  Go beyond destinations; choose experience. Are you tired of generic vacation packages? Choose your theme—adventure, serenity, romance, or self-discovery—and we'll design the perfect Indian odyssey.
                  </p>
                </div>
                <div className="btns">
                  <Link to="/service" className="btn1" >
                    Read more
                    <FaArrowRightLong />
                  </Link>
                  <Link to="/book"
                  onClick={props.opensixthform}
                   className="btn2">
                  <FaPhone />  Enquire Now 
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="home-card-box">
                <div className="img-box img2 bg-img-cover">
                </div>
                <div className="card-content">
                  <h3>Customised Holiday Packages</h3>
                  <p>
                  We custom-make dreamlike experiences. Travel is deeply personal, so forget cookie-cutter packages! Our customised itineraries cater to your unique preferences, ensuring the experience of a lifetime.
                  </p>
                </div>
                <div className="btns">
                  <Link to="/service" className="btn1">
                    Read more
                    <FaArrowRightLong />
                  </Link>
                  <Link to="/book" onClick={props.openfifthform} className="btn2">
                  <FaPhone />  Enquire Now 
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="home-card-box">
                <div className="img-box img3 bg-img-cover">
                </div>
                <div className="card-content">
                  <h3>Visa Assistance</h3>
                  <p>
                  Effortless exploration with seamless visa assistance for stress-free planning. We handle visa applications, eliminating paperwork hassles so you can focus on the excitement of your upcoming journey!
                  </p>
                </div>

                <div className="btns">
                  <Link to="/service" className="btn1">
                    Read more
                    <FaArrowRightLong />
                  </Link>
                  <Link to="/book" onClick={props.openthirdform} className="btn2">
                  <FaPhone />  Enquire Now 
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="home-card-box">
                <div className="img-box img4 bg-img-cover">

                </div>
                <div className="card-content">
                  <h3>Air-Ticketing & Hotel Reservations</h3>
                  <p>
                  Planning a vacation can be stressful due to finances. Our dedicated team offers deals on flights and hotel rooms. Message us, and we ensure you get competitive prices, catering to all budgets and styles.
                  </p>
                </div>

                <div className="btns">
                  <Link to="/service" className="btn1">
                    Read more
                    <FaArrowRightLong />
                  </Link>
                  <Link to="/book" onClick={props.openfristform} className="btn2">
                  <FaPhone />  Enquire Now 
                  </Link>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div className="home-card-box">
                <div className="img-box img5 bg-img-cover">
                </div>
                <div className="card-content">
                  <h3>Travel Insurance</h3>
                  <p>
                  Problems don't come knocking, so ensure you travel with confidence! Our comprehensive travel insurance plans safeguard your trip, offering you peace of mind and a safety net no matter where you are!
                  </p>
                </div>
                <div className="btns">
                  <Link to="/service" className="btn1">
                    Read more
                    <FaArrowRightLong />
                  </Link>
                  <Link to="/book" onClick={props.openfourthform} className="btn2">
                  <FaPhone />  Enquire Now 
                  </Link>
                </div>
              </div>
            </SwiperSlide>

            {/* <SwiperSlide>
              {" "}
              <div className="card-box">
                <div className="img-box img6 bg-img-cover">
                  <div className="icon"> Service 6</div>
                </div>
                <div className="card-content">
                  <h3>International SIM Cards</h3>
                  <p>
                    Affordable International SIMs. Stay in touch with loved ones
                    without breaking the bank! Our international SIM cards offer
                    convenient and affordable connectivity at local rates,
                    ensuring seamless communication throughout your travels.
                  </p>
                </div>

                <div className="btns">
                  <Link to="/service" className="btn1">
                    Read more
                    <FaArrowRightLong />
                  </Link>
                  <Link to="/contact" className="btn2">
                    Enquire Now <FaPhoneFlip />
                  </Link>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
      <Img_card />
     
      <Imageswiper/>
    </>
  );
}

export default Home;
