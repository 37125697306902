import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import gallery_img from "../assets/gallery/g-top.jpg";
import Img from "../comps/Img";
import Pagebreak from "../comps/Pagebreak";
import PageTop from "../comps/PageTop";
import "../styles/Gallery.scss";

function Gallery() {
  const targetRef = useRef(null);
  function handleLoading() {
    setVisibleImages(visibleImages + 1);
  }

  
  const [Image, setImages] = useState([]);
  const fetchImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}gallery-images/`
      );
      const imagesData = response.data.data;
      const formattedImages = imagesData.map((data) => ({ image: data.image }));
      setImages(formattedImages);
    } catch (error) {
      console.log(error);
    }
  };


  const a1 = [];
  const a2 = [];
  const a3 = [];



  for (let i = 0; i < Image.length; i++) {
    if (i % 3 === 0) {
      a1.push(Image[i]);
    } else if (i % 3 === 1) {
      a2.push(Image[i]);
    } else {
      a3.push(Image[i]);
    }
  }



  const [visibleImages, setVisibleImages] = useState(2);
  useEffect(() => {
    fetchImages();
  }, []);
  return (
    <>
      <PageTop img={gallery_img} title="Gallery" />
      <Pagebreak greyBreakPage={true} />
      <div className="gallery-grid-parent parent bg-img-cover">
        <div className="gallery-grid-cont cont">
          <div className="row1 row">
            {a1.slice(0, visibleImages).map((item, index) => (
              <Img key={index} imgurl={item.image} />
            ))}
          </div>
          <div className="row2 row">
            {a2.slice(0, visibleImages).map((item, index) => (
              <Img key={index} imgurl={item.image} />
            ))}
          </div>
          <div className="row3 row">
            {a3.slice(0, visibleImages).map((item, index) => (
              <Img key={index} imgurl={item.image} />
            ))}
          </div>
        </div>

        {visibleImages < a1.length && (
          <button
            onClick={() => handleLoading()}
            className="btn loadmore-btn load-more"
          >
            {" "}
            Load More
          </button>
        )}

        <div ref={targetRef}></div>
      </div>
    </>
  );
}

export default Gallery;
