import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'antd';
import airports from '../static/airports.json';

const Complete = ({ placeholder, onSelectAirport, close }) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const autoCompleteRef = useRef(null);

  // Filter function for Indian airports
  const getIndianAirports = () => {
    return airports
      .filter((airport) => airport.country.toLowerCase() === 'india')
      .slice(0, 10) // Get the first 10 airports
      .map((airport) => ({
        value: airport.name,
        label: (
          <div>
            <strong>{airport.name}</strong> ({airport.code})
            <br />
            <small>{airport.city}, {airport.country}</small>
          </div>
        ),
      }));
  };

  useEffect(() => {
    // Set default options on component mount
    setOptions(getIndianAirports());

    // Focus the input field when the component mounts
    if (autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, []);

  const onSearch = (searchText) => {
    if (!searchText) {
      setOptions(getIndianAirports()); // Reset to default options when search text is empty
      return;
    }
    const filteredOptions = airports
      .filter((airport) =>
        airport.name.toLowerCase().includes(searchText.toLowerCase()) ||
        airport.city.toLowerCase().includes(searchText.toLowerCase()) ||
        airport.code.toLowerCase().includes(searchText.toLowerCase())
      )
      .map((airport) => ({
        value: airport.name,
        label: (
          <div>
            <strong>{airport.name}</strong> ({airport.code})
            <br />
            <small>{airport.city}, {airport.country}</small>
          </div>
        ),
      }));

    setOptions(filteredOptions);
  };

  const onSelect = (value, option) => {
    const selectedAirport = airports.find(
      (airport) => airport.name === value || airport.code === value
    );
    if (selectedAirport) {
      onSelectAirport(selectedAirport);
      close();
    }
    console.log('Selected:', selectedAirport);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <AutoComplete
      ref={autoCompleteRef}  // Use ref to access the AutoComplete component
      value={value}
      options={options}
      style={{ width: 300 }}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChange}
      placeholder={placeholder}
      open={true}  // Ensure the dropdown is open when the component is rendered
    />
  );
};

export default Complete;
