import { DateCalendar } from "@mui/x-date-pickers";
import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { IoIosSwap } from "react-icons/io";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./hotel.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaChevronUp } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import CityComplete from "../../comps/CityComplete";
import dayjs from "dayjs";
import { GrClose } from "react-icons/gr";
import toast from "react-hot-toast";
const Hotel = () => {
  const [selectedPrice, setSelectedPrice] = useState("0-1500");
  const handlePriceSelect = (price) => {
    setSelectedPrice(price);
    setIsOpen(false); // Close dropdown after selection
  };

  // ---------------------------------------
  const formatCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const optionsMonthYear = { month: "short", year: "numeric" };
    const monthYear = new Intl.DateTimeFormat("en-US", optionsMonthYear).format(
      date
    );
    const optionsDayOfWeek = { weekday: "long" };
    const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(
      date
    );
    return `${day} ${monthYear} (${dayOfWeek})`;
  };
  const [roomdeals, setRoomdeals] = useState({
    fourrooms: true,
    groupdeals: false,
  });
  const RoomType = ["single room", "double room", "suit room"];
  const [guests, setGuests] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState("1");
  const [selectedAdults, setSelectedAdults] = useState("1");
  const [selectedChildren, setSelectedChildren] = useState("0");

  const [dateToggle, setDateToggle] = useState({
    checkin: false,
    checkout: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
    city: "Goa",
    country: "India",
    price_per_night: "To Be Discussed",
    number_of_rooms: selectedRoom,
    no_of_guests: parseInt(selectedAdults) + parseInt(selectedChildren),
    check_in_date: formatCurrentDate() || "",
    check_out_date: formatCurrentDate() || "",
    deal_type: roomdeals.fourrooms ? "Upto 4 rooms" : "Group Deals",
  });
  useEffect(() => {
    setFormData({
      ...formData,
      price_per_night: selectedPrice,
    });
  }, [selectedPrice]);
  useEffect(() => {
    setFormData({
      ...formData,
      number_of_rooms: selectedRoom,
      no_of_guests: parseInt(selectedAdults) + parseInt(selectedChildren),
    });
  }, [selectedRoom, selectedAdults, selectedChildren]);

  const [flight, setflight] = useState({
    depDate: false,
    travelClass: false,
  });

  const closeAll = () => {
    setflight({
      depDate: false,
      travelClass: false,
    });
  };
  const onHandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      no_of_guests: guests,
    });
  }, [guests]);

  console.log(formData);

  const [depDate, setdepDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      console.log(`Day: ${day}, Month: ${month}, Year: ${year}`);

      setSelectedDate(date);
    }
    setdepDate(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  const roomNumber = ["1", "2", "3", "5", "6", "7"];
  const adults = ["1", "2", "3", "5", "6", "7"];
  const kids = ["0", "1", "2", "3", "5", "6", "7"];

  const [autocomplete, setAutocomplete] = useState(false);

  const [selectedcity, setSelectedCity] = useState({
    city: "Goa" || "",
    country: "India" || "",
  });
  const handleCityChange = (value) => {
    setSelectedCity(value);
    setFormData({
      ...formData,
      city: value.city,
      country: value.country,
    });
  };
  console.log(selectedcity);

  // date formatting

  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    if (isNaN(date.getTime()))
      return { day: "", month: "", year: "", weekday: "" };
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const weekday = date.getDay();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return `${day < 10 ? `0${day}` : day} ${months[month]} ${year} (${
      weekdays[weekday]
    })`;
  };

  // check in date handler
  const [selectedcheckin, setSelectedCheckIn] = useState(null);
  const handleCheckInChange = (newDate) => {
    console.log("====dep", newDate);
    const formattedDate = formatISODate(newDate);
    setSelectedCheckIn(newDate);
    setFormData((prevData) => ({
      ...prevData,
      check_in_date: formattedDate,
    }));
  };

  // checkout date handler
  const [selectedcheckout, setSelectedCheckOut] = useState(null);
  const handleCheckOutChange = (newDate) => {
    console.log("====dep", newDate);
    const formattedDate = formatISODate(newDate);
    setSelectedCheckOut(newDate);
    setFormData((prevData) => ({
      ...prevData,
      check_out_date: formattedDate,
    }));
  };

  console.log("hotelformdata______", formData);

  useEffect(() => {
    setFormData({
      ...formData,
      deal_type: roomdeals.fourrooms ? "Upto 4 rooms" : "Group Deals",
      price_per_night: roomdeals.fourrooms ? "0 - 1500" : "Not Applicable",
    });
  }, [roomdeals]);

  const [toggle, settoggle] = useState(false);

  const handleformSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}hotel-booking/`,
        formData
      );
      toast.success("Form Submitted !");
      settoggle(false);
      setFormData({
        name: "",
        email: "",
        contact: "",
        message: "",
        city: "",
        country: "",
        price_per_night: "0-1500",
        number_of_rooms: selectedRoom,
        no_of_guests: parseInt(selectedAdults) + parseInt(selectedChildren),
        check_in_date: formatCurrentDate() || "",
        check_out_date: formatCurrentDate() || "",
        deal_type: roomdeals.fourrooms ? "Upto 4 rooms" : "Group Deals",
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="form">
        <form action="">
          <div className="top">
            <div className="left">
              <div
                className={roomdeals.fourrooms ? "trip1 active" : "trip1"}
                onClick={() =>
                  setRoomdeals({
                    fourrooms: true,
                    groupdeals: false,
                  })
                }
              >
                <div className="circle">
                  {roomdeals.fourrooms && <TiTick />}
                </div>
                <p>Up to 4 Room</p>
              </div>
              <div
                className={roomdeals.groupdeals ? "trip1 active" : "trip1"}
                onClick={() =>
                  setRoomdeals({
                    fourrooms: false,
                    groupdeals: true,
                  })
                }
              >
                <div className="circle">
                  {roomdeals.groupdeals && <TiTick />}
                </div>
                <p>Group Deals</p>
              </div>
            </div>
          </div>

          <div className="middle-form" id="oneway">
            {(roomdeals.fourrooms || roomdeals.groupdeals) && (
              <>
                <div
                  className="from from-div"
                  onClick={() => setAutocomplete(true)}
                >
                  <p className="top-tag">city, property name, location </p>
                  <h3>{selectedcity.city}</h3>
                  <p
                    className="place-tag"
                    onClick={() => setAutocomplete(true)}
                  >
                    {selectedcity.country}
                  </p>
                  {autocomplete && (
                    <div
                      className="autocomplete"
                      onBlur={() => setAutocomplete(false)}
                    >
                      <CityComplete
                        close={() => setAutocomplete(false)}
                        placeholder="Select City"
                        onSelectLocation={handleCityChange}
                      />
                    </div>
                  )}
                </div>

                <div
                  className="from departure"
                  onClick={() =>
                    setDateToggle({ ...dateToggle, checkin: true })
                  }
                >
                  <p className="top-tag">
                    Check In{" "}
                    <span>
                      <FaChevronDown />
                    </span>
                  </p>
                  <h4>{formData.check_in_date || formatCurrentDate()}</h4>
                  {dateToggle.checkin && (
                    <div
                      className="calender-parent"
                      onMouseLeave={() => {
                        setDateToggle({ ...dateToggle, checkin: false });
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          className="calender"
                          onChange={handleCheckInChange}
                          value={selectedcheckin}
                          minDate={dayjs()}
                        />
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
                <div
                  className="from departure"
                  onClick={() =>
                    setDateToggle({ ...dateToggle, checkout: true })
                  }
                >
                  <p className="top-tag">
                    Check Out{" "}
                    <span>
                      <FaChevronDown />
                    </span>
                  </p>
                  <h4>{formData.check_out_date || formatCurrentDate()}</h4>
                  {dateToggle.checkout && (
                    <div
                      className="calender-date"
                      onMouseLeave={() => {
                        setDateToggle({ ...dateToggle, checkout: false });
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          className="calender"
                          onChange={handleCheckOutChange}
                          value={selectedcheckout}
                          minDate={dayjs()}
                        />
                      </LocalizationProvider>
                    </div>
                  )}
                </div>

                <div
                  className="from  new travellersclass"
                  onClick={() => {
                    closeAll();
                    setflight((element) => ({
                      ...element,
                      travelClass: true,
                    }));
                  }}
                >
                  <p className="top-tag">
                    Traveller & Class
                    <span>
                      <FaChevronDown />
                    </span>
                  </p>
                  <h3 className="date">
                    {parseInt(selectedAdults) + parseInt(selectedChildren)}
                    <span className="month">Travellers</span>
                  </h3>
                  <p className="place-tag">Economy/Premium Economy</p>

                  {flight.travelClass && (
                    <div
                      className="travle-class rooms"
                      onMouseLeave={() =>
                        setflight({ ...flight, travelClass: false })
                      }
                    >
                      <div className="adultsroom">
                        <p>rooms</p>
                        <select
                          className="roomlist"
                          onChange={(e) => {
                            setSelectedRoom(e.target.value);
                          }}
                          value={selectedRoom}
                        >
                          {roomNumber.map((roomNo) => (
                            <option key={roomNo} value={roomNo}>
                              {roomNo}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="adultsroom">
                        <p>Adults</p>
                        <select
                          className="roomlist"
                          onChange={(e) => {
                            setSelectedAdults(e.target.value);
                          }}
                          value={selectedAdults}
                        >
                          {adults.map((roomNo) => (
                            <option key={roomNo} value={roomNo}>
                              {roomNo}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="adultsroom">
                        <p>Children</p>
                        <select
                          className="roomlist"
                          onChange={(e) => {
                            setSelectedChildren(e.target.value);
                          }}
                          value={selectedChildren}
                        >
                          {kids.map((roomNo) => (
                            <option key={roomNo} value={roomNo}>
                              {roomNo}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="adultsroom">
                        <button
                          className="btn"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setflight({ ...flight, travelClass: false });
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {/* {roomdeals.fourrooms && (
              <div
                className="from priceNight"
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className="top-tag">
                  <span>
                    <p>Price Per Night </p>

                    {isOpen ? (
                      <FaChevronUp onClick={() => setIsOpen(!isOpen)} />
                    ) : (
                      <FaChevronDown onClick={() => setIsOpen(!isOpen)} />
                    )}
                  </span>
                  <div className="price">
                    <h3>{selectedPrice}</h3>
                  </div>
                  <span></span>
                </div>

                {isOpen && (
                  <div className="dropdown">
                    <p onClick={() => handlePriceSelect("0-1500")}>0-1500</p>
                    <p onClick={() => handlePriceSelect("1500-3000")}>
                      1500-3000
                    </p>
                    <p onClick={() => handlePriceSelect("3000-6000")}>
                      3000-6000
                    </p>
                    <p onClick={() => handlePriceSelect("6000-9000")}>
                      6000-9000
                    </p>
                  </div>
                )}
              </div>
            )} */}
          </div>
          <div className="home-button">
            <button
              className="btn"
              type="button"
              onClick={() => settoggle(true)}
            >
              Enquire Now
            </button>
          </div>
        </form>
      </div>

      {/* contact form */}
      {toggle && (
        <>
          <div className="popup-parent parent">
            <div className="popup-cont container">
              <div className="close-pop" onClick={() => settoggle(false)}>
                <GrClose />
              </div>
              <div className="contact-form">
                <h2 className="contact-heading">Enquire Now</h2>

                <div className="input-div">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email Id "
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="text"
                    name="Number"
                    placeholder="Mobile Number"
                    onChange={(e) =>
                      setFormData({ ...formData, contact: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <textarea
                    name="Message"
                    cols="46"
                    rows="3"
                    placeholder="Message"
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="btn-div">
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleformSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Hotel;
