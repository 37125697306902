import React, { useState } from "react";
import "../styles/Contact.scss";
import PageTop from "../comps/PageTop";
import blog_img from "../assets/service/2.jpg";
import SectionIndicator from "../comps/SectionIndicator";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { getDatabase, ref, set, push } from "firebase/database";
import { app } from "../Firebase";
import { notification } from "antd";
import Pagebreak from "../comps/Pagebreak";
import axios from "axios";

function Contact() {
  const [api, contextHolder] = notification.useNotification();
  const [contactDetail, setContactDetail] = useState({
    fullname: "",
    email: "",
    message: "",
  });
  const openNotificationWithIcon = () => {
    api.success({
      message: "Message sent successfully!",
      description: "Thank you for contacting us",
    });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = axios.post(`${process.env.REACT_APP_URL}contact/`,contactDetail)
      setContactDetail({
        fullname: "",
        email: "",
        message: "",
      });
      openNotificationWithIcon();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageTop img={blog_img} title="Contact us" />
      <Pagebreak greyBreakPage={true} />
      <div className="contact-parent parent">
        <div className="contact-cont cont">
          <div className="contact-left">
            <SectionIndicator indicator="GET IN TOUCH" />
            <h2 className="conatct-heading">Let’s Craft Your Indian Journey Together! </h2>
            <h5 className="contact-para">
            Fill out the form, and we will get in touch.
            </h5>
            {contextHolder}
            <form className="contact-form" onSubmit={formSubmit}>
              <input
                type="text"
                placeholder="Your Name*"
                value={contactDetail.fullname}
                required={true}
                onChange={(e) => {
                  setContactDetail({
                    ...contactDetail,
                    fullname: e.target.value,
                  });
                }}
              />
              <input
                type="email"
                required={true}
                placeholder="Your Email*"
                value={contactDetail.email}
                onChange={(e) =>
                  setContactDetail({
                    ...contactDetail,
                    email: e.target.value,
                  })
                }
              />
              <textarea
              required={true}
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Your Message*"
                value={contactDetail.message}
                onChange={(e) =>
                  setContactDetail({
                    ...contactDetail,
                    message: e.target.value,
                  })
                }
              ></textarea>
              <button type="submit" className="btn">
                Submit Message
              </button>
            </form>
          </div>

          {/* Right */}

          <div className="contact-right">
            <h3 className="c-r-h3">Contact Us Today!</h3>
            <p className="c-r-para">
             At The Indian Journey, we're passionate about creating unforgettable travel experiences in India. Whether you have a question, a specific trip in mind, or just want to chat about your travel dreams, we'd love to hear from you!

            </p>

            {/* <p className="c-r-para">
              Nostra doloribus blandit et semper ultrices, quibusdam
              dignissimos! Netus recusandae, rerum cupidatat. Perferendis aptent
              wisi.
            </p> */}

            <div className="details-list">
              <div className="details-cont">
                <div className="email">
                  <div className="c-icon">
                    <AiOutlineMail />
                  </div>
                  <div className="location-right">
                    <div className="l-top">
                      <a>Email Address</a>
                    </div>
                    <div className="l-bottom">
                      <a
                        href="mailto:info@theindianjourney.com"
                        target="_blank"
                      >
                        info@theindianjourney.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="phone">
                  <div className="c-icon">
                  <MdOutlinePhoneInTalk />
                  </div>
                  <div className="location-right">
                    <div className="l-top">
                      <a>Phone Number</a>
                    </div>
                    <div className="l-bottom">
                      <a href="tel:+91 7666601972" target="_blank">
                        +91 7666601972
                      </a>
                    </div>
                  </div>
                </div>
                <div className="location">
                  <div className="c-icon">
                    <SlLocationPin />
                  </div>
                  <div className="location-right">
                    <div className="l-top">
                      <a>Location Address</a>
                    </div>
                    <div className="l-bottom">
                      <a
                        href="https://maps.app.goo.gl/jf2eUYVm9uDzL7Sy6"
                        target="_blank"
                      >
                        Office No. 214-216, 10 Biz Park, Near Symbiosis Law
                        College, Airport Road, Vimannagar, Pune- 411014
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-social-links">
              <h3 className="media-text">Follow us on social media..</h3>
              <div className="social-icons">
                <span className="media-icons">
                  <a href="https://www.facebook.com/theindianjourney" target="_blank">
                    <FaFacebookF />
                  </a>
                </span>
                <span className="media-icons">
                  <a href="https://x.com/TIJ_INDIA" target="_blank">
                    <FaXTwitter />
                  </a>
                </span>
                <span className="media-icons">
                  <a href="https://www.instagram.com/theindianjourney/?hl=en" target="_blank">
                    <FaInstagram />
                  </a>
                </span>
                <span className="media-icons">
                  <a href="https://www.linkedin.com/company/the-indian-journey/" target="_blank">
                    <FaLinkedinIn />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
