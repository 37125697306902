import React, { useEffect } from "react";
import "../styles/Blog_comp.scss";
import { Link } from "react-router-dom";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

function Blog_comp(props) {
  const limitWords = (text, limit) => {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };
  const limitedPara = limitWords(props.para, 30);
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDateLong = date.toLocaleDateString("en-US", options);
    return formattedDateLong;
  }

  return (
    <>
      <div className="blog-container1">
        <div
          className="img bg-cover"
          style={{ background: `url(${props.image})` }}
        ></div>
        <h3 className="blog-h3">
          <Link to={`/blogPage/${props.link}`} className="blog-h3">
            {truncateText(props.title, 8)}
          </Link>
        </h3>
        <div className="date-time-cat">
          <span className="date">{formatDate(props.date)}</span>
          <span className="category">{props.category}</span>
        </div>
        <p
          className="para"
          dangerouslySetInnerHTML={{ __html: limitedPara }}
        ></p>
        <Link to={`/blogPage/${props.link}`} className="c-read">
          CONTINUE READING..
        </Link>
      </div>
    </>
  );
}

export default Blog_comp;
