import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import "../styles/Header2.scss";
import { RiArrowDropDownLine } from "react-icons/ri";
import Hamburger from "hamburger-react";
import { IoClose } from "react-icons/io5";



function Header2(props) {
  const [navopen, setNavOpen] = useState(false);
  const [navClass, setNavClass] = useState(false);
  const [bookNow, setBookNow] = useState(false);
  const [open, setOpen] = useState(false); // Corrected variable name from 'setopen' to 'setOpen'

  useEffect(() => {
    function handleOpen() {
      setNavOpen(window.innerWidth >= 800);
    }
    handleOpen();
    window.addEventListener("resize", handleOpen);
    return () => {
      window.removeEventListener("resize", handleOpen); // Corrected event listener removal
    };
  }, []);
  const location = useLocation();
  useEffect(() => {
    function toggleNav() {
      if (window.scrollY > 0) {
        setNavClass(true);
      } 
      else if(location.pathname !== "/privacy")
      {
        setNavClass(false);
      }
    }
    toggleNav();
    window.addEventListener("resize", toggleNav);
    window.addEventListener("scroll", toggleNav);
    return () => {
      window.addEventListener("resize", toggleNav);
      window.addEventListener("scroll", toggleNav);
    };
  }, [location]);

  function handleMob() {
    setOpen(!open);
    props.setMobOverlay(!props.moboverlay);
  }

  const items = [
    {
      key: "1",
      label: "Study Journey",
      link: "/study_journey",
      disabled: false,
    },
    { key: "2", label: "Sport Journey", link: "/sport", disabled: false },
    {
      key: "3",
      label: "Culinary Journey",
      link: "/culinary_journey",
      disabled: false,
    },
    {
      key: "4",
      label: "History & Cultural Journey",
      link: "/history",
      disabled: false,
    },
    {
      key: "5",
      label: "Photography Journey",
      link: "/photography",
      disabled: false,
    },
    {
      key: "6",
      label: "Yoga & Meditation Journey",
      link: "/yoga",
      disabled: false,
    },
    { key: "7", label: "AcroYoga Journey", link: "/acroyoga", disabled: false },
    { key: "8", label: "Event Journey", link: "/event", disabled: false },
  ];

  

  useEffect(()=>
  {
   if(location.pathname == "/privacy") 
   {
    setNavClass(true);
   }
  },[location])
  
  return (
    <>
      <div
        className={
          navClass ? "header2-parent parent scrolled" : "header2-parent parent"
        }
      >
        <div
          className={
            bookNow ? "book-now-overlay expand-height" : "book-now-overlay"
          }
        ></div>
        <div className="header2-cont cont">
          <Link className="h-logo bg-img-contain" to="/"></Link>

          {navopen ? (
            <div className="navbar">
              <div className="h-nav">
                <Link to="/">Home</Link>
                <Link to="/AboutUs">About</Link>
                <Link to="/service">Service</Link>
                <Dropdown
                  overlay={
                    <Menu>
                      {items.map((item) => (
                        <Menu.Item key={item.key} disabled={item.disabled}>
                          <Link to={item.link}>{item.label}</Link>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Link className="journey">
                    Journey <DownOutlined />
                  </Link>
                </Dropdown>
                <Link to="/blog">Blog</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/contact">Contact</Link>
              </div>
              <div className="h-booknow">
                <Link
                  to="/book"
                  className="book-now-button"
                  onMouseOver={() => setBookNow(true)}
                  onMouseOut={() => setBookNow(false)}
                >
                  Enquire Now
                </Link>
              </div>
            </div>
          ) : (
            <div className="mob-links">
              <div className="close">
                <Hamburger toggled={open} toggle={handleMob} />
              </div>
            </div>
          )}
        </div>
        {open && (
          <div className={navClass ? "mobnav scrolled" : "mobnav"}>
            <div className="close-btn" onClick={() => setOpen(false)}>
              <IoClose />{" "}
            </div>
            <div className="moblinks">
              <Link to="/" onClick={() => setOpen(!open)}>
                Home
              </Link>
              <Link to="/service" onClick={() => setOpen(!open)}>
                Service
              </Link>
              <Dropdown
                overlay={
                  <Menu>
                    {items.map((item) => (
                      <Menu.Item key={item.key} disabled={item.disabled}>
                        <Link to={item.link} onClick={() => setOpen(!open)}>
                          {item.label}
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                placement="bottomCenter"
              >
                <Link className="arrow-mob">
                  Journey
                  <span className="header-arrow1">
                    <RiArrowDropDownLine />
                  </span>
                </Link>
              </Dropdown>
              <Link to="/blog" onClick={() => setOpen(!open)}>
                Blog
              </Link>
              <Link to="/gallery" onClick={() => setOpen(!open)}>
                Gallery
              </Link>
              <Link to="/contact" onClick={() => setOpen(!open)}>
                Contact
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header2;
