import React from 'react'
import Pagebreak from './Pagebreak'
import "../styles/Pagetop.scss"

function PageTop(props) {
  return (
    <>
    <div className='parent pagetop bg-img-cover'  style={{ background: `url('${props.img}')` }} >
        <h1>{props.title}</h1>
        <div className="overlay"></div>
      </div>
      {/* <Pagebreak 
      greyBreakPage={true}
      /> */}
    
    </>
  )
}

export default PageTop
