import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import CountryAutoComplete from "../../comps/CountryAutoComplete";
import "./vessains.scss";
import dayjs from "dayjs";
import axios from "axios";
import toast from "react-hot-toast";

const VisaForm = () => {
  const [depDate, setDepDate] = useState(false);
  const [destinationOpen, setDestinationOpen] = useState(false);
  const [countryOpen, setCountryOpen] = useState(false);
  const [peopleOpen, setPeopleOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedDate, setSelectedDate] = useState(null);

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const closeAllDropdowns = () => {
    setDestinationOpen(false);
    setCountryOpen(false);
    setPeopleOpen(false);
  };

  const [trip, setTrip] = useState({
    oneWay: true,
    returnTrip: false,
    multiCity: false,
  });

  const closeAllTrip = () => {
    setTrip({
      oneWay: true,
      returnTrip: true,
      multiCity: true,
    });
  };
  const [flight, setflight] = useState({
    depDate: false,
    travelClass: false,
    returnDate: false,
  });
  // closing all flight states
  const closeAll = () => {
    setflight({
      depDate: false,
      travelClass: false,
      returnDate: false,
    });
  };

  const handleCountrySelect = (value) => {
    setSelectedCountry(value.name);
  };
  const adults = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const children = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const infants = ["0","1", "2", "3", "4", "5", "6", "7"];
  const travelclass = [
    "Economy/Premium Economy",
    "Premium Economy",
    "Business",
  ];
  const handleSeatClick = (value) => {
    setFormData({ ...formData, adults_no: value });
  };
  const handlechildClick = (value) => {
    setFormData({ ...formData, kids_no: value });
  };
  const handleinfantClick = (value) => {
    setFormData({ ...formData, infants: value });
  };
  const handleclassClick = (value) => {
    setFormData({ ...formData, travel_class: value });
  };

  // handling date
  const formatISODate = (isoString) => {
    const date = new Date(isoString);
    if (isNaN(date.getTime()))
      return { day: "", month: "", year: "", weekday: "" };
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const weekday = date.getDay();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return `${day < 10 ? `0${day}` : day} ${months[month]} ${year} (${
      weekdays[weekday]
    })`;
  };

  const formatCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const optionsMonthYear = { month: "short", year: "numeric" };
    const monthYear = new Intl.DateTimeFormat("en-US", optionsMonthYear).format(
      date
    );
    const optionsDayOfWeek = { weekday: "long" };
    const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(
      date
    );
    return `${day} ${monthYear} (${dayOfWeek})`;
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    adults_no: "1",
    kids_no: "0",
    infants: "0",
    travel_class: "Economy/Premium Economy",
    destination: selectedCountry,
    date_of_travel: formatCurrentDate() || "",
  });
  const handleDateChange = (newDate) => {
    const formattedDate = formatISODate(newDate);
    setSelectedDate(newDate);
    setFormData((prevData) => ({
      ...prevData,
      date_of_travel: formattedDate,
    }));
  };

  useEffect(() => {
    setFormData({
      ...formData,
      destination: selectedCountry,
    });
  }, [selectedCountry]);

  console.log("my form date____", formData);
  const handleformSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}visa-booking/`,
        formData
      );
      console.log(response);
      toast.success("Message submitted.")
      setFormData({
        name: "",
        email: "",
        mobile: "",
        message: "",
        adults_no: "1",
        kids_no: "0",
        infants: "0",
        travel_class: "Economy/Premium Economy",
        destination: selectedCountry,
        date_of_travel: formatCurrentDate() || "",
      });
      setModal(false)
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="form">
        <form action="" className=" form22">
          <div className="middle-form" id="oneway">
            {/* Destination Field */}
            <div
              className="from from-div"
              onClick={() => {
                closeAllDropdowns();
                setDestinationOpen(!destinationOpen);
              }}
            >
              <h3>Destination</h3>
              <p className="place-tag">{selectedCountry}</p>
              {destinationOpen && (
                <div
                  className="autocomplete"
                  onBlur={() => setDestinationOpen(false)}
                >
                  <CountryAutoComplete
                    placeholder="Select country"
                    close={() => setDestinationOpen(false)}
                    onSelectCountry={handleCountrySelect}
                  />
                </div>
              )}
            </div>

            {/* Date of Travel Field */}
            <div
              className="from departure"
              onClick={() => setDepDate(true)}
              onMouseLeave={() => setDepDate(false)}
            >
              <p className="top-tag">Date of Travel</p>
              <h4>{formData.date_of_travel || "sdoifhsdiu"}</h4>
              {depDate && (
                <div className="calender-box">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      className="calender"
                      onChange={handleDateChange}
                      value={selectedDate}
                      minDate={dayjs()}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>

            {/* Number of People Field */}
            <div
              className="from travellersclass"
              onClick={() => {
                // closeAll();
                setflight((element) => ({
                  ...element,
                  travelClass: true,
                }));
              }}
            >
              <p className="top-tag">
                Traveller & Class{" "}
                <span>
                  <FaChevronDown />
                </span>
              </p>
              <h3 className="date">
                {/* {dateFetch.day} */}
                {parseInt(formData.adults_no) +
                  parseInt(formData.kids_no) +
                  parseInt(formData.infants)}
                <span className="month">Travellers</span>
              </h3>
              <p className="place-tag">{formData.travel_class}</p>

              {flight.travelClass && (
                <div
                  className="travle-class"
                  onMouseLeave={(prev) =>
                    setflight({ ...prev, travelClass: false })
                  }
                >
                  <div className="taps adults">
                    <div className="top">
                      <h5>ADULTS(12Y +)</h5>
                      <p>on the day of travel</p>
                    </div>
                    <div className="taps-list ">
                      {adults.map((adults, index) => (
                        <span
                          key={index}
                          className={`seat${adults} seat ${
                            formData.adults_no == adults ? "active" : ""
                          }`}
                          onClick={() => handleSeatClick(adults)}
                        >
                          {adults === "more than 9" ? "+9" : adults}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="taps children">
                    <div className="top">
                      <h5>CHILDREN(2y - 12y)</h5>
                      <p>on the day of travel</p>
                    </div>
                    <div className="taps-list ">
                      {children.map((kids, index) => (
                        <span
                          key={index}
                          className={`seat${kids} seat ${
                            formData.kids_no == kids ? "active" : ""
                          }`}
                          onClick={() => handlechildClick(kids)}
                        >
                          {kids === "more than 6" ? "+6" : kids}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="taps infants">
                    <div className="top">
                      <h5>INFANTS (below 2y)</h5>
                      <p>on the day of travel</p>
                    </div>
                    <div className="taps-list ">
                      {infants.map((infants, index) => (
                        <span
                          key={index}
                          className={`seat${infants} seat ${
                            formData.infants == infants ? "active" : ""
                          }`}
                          onClick={() => handleinfantClick(infants)}
                        >
                          {infants === "more than 6" ? "+6" : infants}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="taps class">
                    <div className="top">
                      <h5>CHOOSE TRAVEL CLASS</h5>
                    </div>
                    <div className=" class">
                      <div className="left">
                        {travelclass.map((data, index) => (
                          <span
                            className={`economybtn ${
                              formData.travel_class === data ? "active" : ""
                            }`}
                            onClick={() => handleclassClick(data)}
                          >
                            {data}
                          </span>
                        ))}
                      </div>
                      <div className="right">
                        <span
                          className="apply"
                          onClick={(e) => {
                            e.stopPropagation();
                            setflight((element) => ({
                              ...element,
                              travelClass: false,
                            }));
                          }}
                        >
                          Apply
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Link className="btn" onClick={toggleModal}>
            Enquire Now
          </Link>
        </form>
      </div>

      {modal && (
        <>
          <div className="popup-parent parent">
            <div className="popup-cont container">
              <div className="close-pop" onClick={() => setModal(false)}>
                <GrClose />
              </div>
              <div className="contact-form">
                <h2 className="contact-heading">Enquire Now</h2>

                <div className="input-div">
                  <input
                    type="text"
                    name="Name"
                    placeholder="Name"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email Id "
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <input
                    type="text"
                    name="Number"
                    placeholder="Mobile Number"
                    onChange={(e) =>
                      setFormData({ ...formData, mobile: e.target.value })
                    }
                  />
                </div>
                <div className="input-div">
                  <textarea
                    name="Message"
                    cols="46"
                    rows="3"
                    placeholder="Message"
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="btn-div">
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleformSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VisaForm;
