import React, { useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import Slider from "@mui/material/Slider";
import "../../src/styles/progressbar.scss";
import { LiaRupeeSignSolid } from "react-icons/lia";
const BudgetProgressBar = (props) => {
  const [value2, setValue2] = useState([0, 100]);
  const [nightRange, setNightRange] = useState([41000, 193000]);
  const minDistance = 10;

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }

    const totalBudget = 193000;
    const newNightRange = [
      Math.round((newValue[0] / 100) * totalBudget),
      Math.round((newValue[1] / 100) * totalBudget),
    ];
    setNightRange(newNightRange);
    console.log(newNightRange);
  };

  const moveProgress = (index) => {
    switch (index) {
      case 1:
        setValue2([0, 50]);
        setNightRange([41000, 70000]);
        console.log(nightRange);
        break;
      case 2:
        setValue2([50, 60]);
        setNightRange([70000, 85000]);
        break;
      case 3:
        setValue2([60, 70]);
        setNightRange([85000, 110000]);
        break;
      case 4:
        setValue2([70, 100]);
        setNightRange([110000, 193000]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    props.setrange(nightRange);
  }, [nightRange]);
  return (
    <>
      <p className="tag">
        Budget (per person) ({" "}
        <span>
          {" "}
          <LiaRupeeSignSolid />{" "}
        </span>{" "}
        {nightRange[0]} -{" "}
        <span>
          <LiaRupeeSignSolid />
        </span>{" "}
        {nightRange[1]})
      </p>

      <div className="progress-bar">
        <Slider
          getAriaLabel={() => "Minimum distance shift"}
          value={value2}
          onChange={handleChange2}
          valueLabelDisplay="auto"
          disableSwap
          className="bar"
        />
      </div>

      <div className="taps">
        <p className="tapone" onClick={() => moveProgress(1)}>
          <span className="lessIcon">
            <RiArrowLeftSLine />
          </span>
          70000 
        </p>
        <p className="tapone" onClick={() => moveProgress(2)}>
          70,800 - 85,000
        </p>
        <p className="tapone" onClick={() => moveProgress(3)}>
          85,000 -1,10,000 
        </p>
        <p className="tapone" onClick={() => moveProgress(4)}>
          <span className="greaterIcon">
            <RiArrowLeftSLine />
          </span>
          1,10,000
        </p>
      </div>
    </>
  );
};

export default BudgetProgressBar;
