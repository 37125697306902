// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-jS2LopIQCSmGz2lBQ0Ti_jDIQvuflMM",
  authDomain: "thetraveljourney.firebaseapp.com",
  projectId: "thetraveljourney",
  storageBucket: "thetraveljourney.appspot.com",
  messagingSenderId: "628151168783",
  appId: "1:628151168783:web:354710e92454f4fadd187a",
  measurementId: "G-6G7HVZ93RN",
  databaseURL:"https://thetraveljourney-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);