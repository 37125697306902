import React from "react";
import "../styles/ServicePage.scss";
import SectionIndicator from "./SectionIndicator";
import ImageBox from "./ImageBox";
import { Link } from "react-router-dom";
import PageTop from "./PageTop";
import img1 from "../assets/bg.jpg";
import { FaCheck } from "react-icons/fa";

function ServicePage(props) {
  const midpoint = props.cheakdata && Math.ceil(props.cheakdata.length / 2);
  return (
    <div className="parent  service-sectionn" style={{ background: props.bg }}>
      <div className="content-box cont">
        <h2>{props.title}</h2>
        <p>{props.subdesc}</p>
      </div>
      <div
        className="service-section-cont cont"
        style={{ flexDirection: props.direction }}
      >
        <div className="left">
          <ImageBox
            imghigh={props.imghigh}
            imgheading={props.imgheading}
            // height={props.height}
            // width={props.width}
            imgurl={props.imgurl}
          />
        </div>
        <div className="right">
          <SectionIndicator indicator={props.indicator} />
          {props.heading && <h2> {props.heading}</h2>}
          {props.desc && <p>{props.desc}</p>}

          {props.children}
         {props.listtitle && <h3>{props.listtitle}</h3>}
          <div className="list-box">
            <div className="list-one">
              <ul className="lists">
                {props.cheakdata &&
                  props.cheakdata.slice(0, midpoint).map((data) => {
                    return (
                      <li>
                        <span className="icon">
                          <FaCheck />
                        </span>
                        {data}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="list-two lists">
              <ul className="lists">
                {/* <h3>{props.listtitle1}</h3> */}
                {props.cheakdata &&
                  props.cheakdata.slice(midpoint).map((data) => {
                    return (
                      <li>
                        <span className="icon">
                          <FaCheck />
                        </span>
                        {data}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          {props.btntext && (
            <Link className="btn" to={props.btnlink}>
              {props.btntext}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
