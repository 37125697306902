import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.scss";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import IMG from "../assets/logo_white.png";
import Beforefooter from "./Beforefooter";


function Footer(props) {

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <>

      <div className="footer-parent parent">
        <div className="footer-container cont">
          <div className="footer-top-section">
            <h2 className="footer-top-heading">Embark on Your Dream Journey - Adventure Awaits!</h2>
            <Link className="btn" to="/contact">Connect with us</Link>
          </div>

          <div className="footer-content-box">
            <div className="footer-pages">
              <h3>About The Indian Journey</h3>
              <p>We offer themed travel experiences customised to your specific needs. Whether you're seeking adventure, relaxation, or cultural immersion, every detail is tailored to make your journey truly unforgettable.</p>
              <div className="logo-ti"><Link to="/">
                <img src={IMG} />
              </Link></div>

            </div>
            <div className="footer-services">
              <p>Pages</p>
              <div className="navs">
                <Link to="/">Home</Link>
                <Link to="/AboutUs">About</Link>
                <Link to="/service">Services</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/contact">Contact</Link>
                {/* <Link to="/gallery">Gallery</Link> */}
              </div>
            </div>
            <div className="footer-legal">
              <p>Other Pages</p>
              <Link to="/privacy">Privacy</Link>
              {/* <Link to="#">Cookies</Link> */}
              {/* <Link to="#">Sitemap</Link> */}
              <Link to="/terms&condition">Terms & Conditions</Link>
            </div>

            <div className="footer-contact">
              <div className="footer-phone">
                <span className="footer-icon-glass">
                  <FaPhone />
                </span>
                <span className="footer-icon">
                  <a href="tel:+91 7666601972" target="_blank">
                    +91 7666601972
                  </a>
                </span>
              </div>

              <div className="footer-mail">
                <span className="footer-icon-glass">
                  <IoMdMail />
                </span>
                <span className="footer-icon">
                  <a href="mailto:info@theindianjourney.com" target="_blank">
                    info@theindianjourney.com
                  </a>
                </span>
              </div>

              <div className="footer-add">

                <span className="footer-icon-glass">
                  <FaLocationDot />
                </span>
                <span className="footer-icon">
                  <a
                    href="https://maps.app.goo.gl/jf2eUYVm9uDzL7Sy6"
                    target="_blank"
                  >
                    Office No. 214-216, 10 Biz Park, Near Symbiosis Law College, Airport Road,Vimannagar, Pune - 411014
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------Footer-bottom-section----------------- */}

      <div className="footer-bottom-content-box parent">
        <div className="footer-bottom-content-box-container cont">
          <div className="footer-social-icons ">
            <span>
              <a href="https://www.facebook.com/theindianjourney" target="_blank">
                <FaFacebookF />
              </a>
            </span>

            <span>
              <a href="https://x.com/TIJ_INDIA" target="_blank">
                <FaXTwitter />
              </a>
            </span>
            <span>
              <a
                href="https://www.linkedin.com/company/the-indian-journey/"
                target="_blank"
              >
                <FaLinkedinIn />
              </a>
            </span>
            <span>
              <a href="https://www.instagram.com/theindianjourney/?hl=en" target="_blank">
                <FaInstagram />
              </a>
            </span>
          </div>
          <div className="footer-copyright">
            <p>
              Copyright©2024 | All Rights Reserved | Designed &
              Developed by{" "}
              <Link to="https://diwise.uk/" target="_blank">
                Diwise
              </Link>
            </p>
          </div>
        </div>
        <button className="gototop btn" onClick={goTop}><FaArrowUp /></button>
      </div>

    </>
  );
}

export default Footer;
