import React from 'react'
import './fourzero.scss';
import { Link } from 'react-router-dom';


function Fourzero() {
    return (
        <>
            <div className="four-zero-parent parent bg-img-cover">
               
                <div className="four-zero-container cont">
                    <h2 className='four-zero-heading'>404</h2>
                    <h4 className='four-zero-subheading'>YOU ARE LOST</h4>
                    <p className='four-p'>That road didn't took you anywhere.</p>
                    <Link to='/' className='btn'>Back To Home</Link>
                </div>

            </div>


        </>
    )
}

export default Fourzero