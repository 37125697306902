import React from 'react'
import  "../styles/FeatureSections.scss"
import SectionIndicator from './SectionIndicator';
import ImageBox from './ImageBox';
import { Link } from 'react-router-dom';

const truncateText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

function FeatureSection(props) {
  
  return (
    <div  className=' feature-section parent ' style={{ background: props.bg }}  >   

        <div className="feature-section-cont cont" style={{flexDirection: props.direction}}   >
            <div className="left">
              <ImageBox 
              imghigh={props.imghigh}  
              imgheading={props.imgheading}  
              // height={props.height}
              // width={props.width}
              imgurl ={props.imgurl}     
              />
            </div>
            <div className="right">
               <SectionIndicator   indicator={props.indicator}  />
              <h2> {props.heading}</h2>
              <p> { props.desc &&  truncateText(props.desc,80)}</p>

              {props.btntext && <Link className='btn'  to={props.btnlink}>{props.btntext}</Link>}
            </div>
        </div>




    
    </div>
  )
}

export default FeatureSection
