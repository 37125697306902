import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Service from "./pages/Service";
import CulinaryJourney from "./pages/CulinaryJourney";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import Book from "./pages/Book";
import StudyJourney from "./pages/StudyJourney";
import Sport from "./pages/Sport";
import History from "./pages/History";
import Photography from "./pages/Photography";
import Yoga from "./pages/Yoga";
import Acroyoga from "./pages/Acroyoga";
import EventJourney from "./pages/EventJourney";
import Footer from "./comps/Footer";
import Header2 from "./comps/Header2";
import { useEffect, useState } from "react";
import Hero from "./comps/Hero";
import Img_card from "./comps/Img_card";
import Scroll from "./comps/Scoll";
import About from "./pages/About";
import BlogPage from "./pages/BlogPage";
import Privacy from "./pages/Privacy";
import Cookies from "./pages/Cookies";
import TermsCondition from "./pages/TermsCondition";
import New_service from "./pages/New_service";
import { Toaster } from "react-hot-toast";
import Fourzero from "./pages/fourzero/Fourzero";

function App() {
  const [moboverlay, setMobOverlay] = useState(false);

  const clearConsole = () => {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
    console.debug = () => {};
    console.trace = () => {};
  };

  useEffect(() => {
    // clearConsole();
  }, []);

  const [nightrange, setNightRange] = useState(null);
  const [pricerange, setPriceRange] = useState(null);

  // book now states

  const [firstform, setfirstform] = useState(true);
  const [secondform, setsecondform] = useState(false);
  const [thirdform, setthirdform] = useState(false);
  const [fourthform, setfourthform] = useState(false);
  const [fifthform, setfifthform] = useState(false);
  const [sixthform, setsixthform] = useState(false);

  function closeall() {
    setfirstform(false);
    setsecondform(false);
    setthirdform(false);
    setfourthform(false);
    setfifthform(false);
    setsixthform(false);
  }

  function openfristform() {
    closeall();
    setfirstform(true);
  }
  function opensecondform() {
    closeall();
    setsecondform(true);
  }
  function openthirdform() {
    closeall();
    setthirdform(true);
  }
  function openfourthform() {
    closeall();
    setfourthform(true);
  }
  function openfifthform() {
    closeall();
    setfifthform(true);
  }
  function opensixthform() {
    closeall();
    setsixthform(true);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header2 setMobOverlay={setMobOverlay} moboverlay={moboverlay} />
        <Toaster />
        <Scroll />
        <Routes>
          <Route path="/" element={<Home 
            openfristform={openfristform}
            opensecondform={opensecondform}
            openthirdform={openthirdform}
            openfourthform={openfourthform}
            openfifthform={openfifthform}
            opensixthform={opensixthform}
          
          />} />
          <Route
            path="/service"
            element={
              <New_service
                openfristform={openfristform}
                opensecondform={opensecondform}
                openthirdform={openthirdform}
                openfourthform={openfourthform}
                openfifthform={openfifthform}
                opensixthform={opensixthform}
              />
            }
          />
          <Route path="/culinary_journey" element={<CulinaryJourney />} />
          <Route path="/study_journey" element={<StudyJourney />} />
          <Route path="/sport" element={<Sport />} />
          <Route path="/history" element={<History />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/yoga" element={<Yoga />} />
          <Route path="/acroyoga" element={<Acroyoga />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/AboutUs" element={<About />} />
          <Route path="/terms&condition" element={<TermsCondition />} />
          <Route path='*' element={<Fourzero/>} />
          <Route
            path="/book"
            element={
              <Book
                setstay={setNightRange}
                getstay={nightrange}
                firstform={firstform}
                secondform={secondform}
                thirdform={thirdform}
                fourthform={fourthform}
                fifthform={fifthform}
                sixthform={sixthform}
                setrange={setPriceRange}
                getrange={pricerange}
                closeall={closeall}
                openfristform={openfristform}
                opensecondform={opensecondform}
                openthirdform={openthirdform}
                openfourthform={openfourthform}
                openfifthform={openfifthform}
                opensixthform={opensixthform}
              />
            }
          />
          <Route path="/Img-card" element={<Img_card />} />
          <Route path="/event" element={<EventJourney />} />
          <Route path="/hero" element={<Hero moboverlay={moboverlay} />} />
          <Route path="/blogPage/:id/" element={<BlogPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookies" element={<Cookies />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
