import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import PageTop from "../comps/PageTop";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../styles/EventJourney.scss";
import Img0 from "../assets/journey/event_journey.webp";
import Img1 from "../assets/gallery/gallery_6.webp";
import ServicePage from "../comps/ServicePage";
import Pagebreak from "../comps/Pagebreak";
import handi from "../assets/swiper/handi.png"
import fire from "../assets/swiper/fire.png"
import cfes from "../assets/swiper/CoachellaFestival.png"
import tland from "../assets/swiper/tLand.png"
import snow from "../assets/swiper/snowbombing.png"
import ganesh from "../assets/swiper/ganeshji.png"
import diwali from "../assets/swiper/diwali.png"
function EventJourney() {
  const eventSlides = [
    {
      title: "Dahi Handi",
      description:
        "Dahi Handi, celebrated in Maharashtra, India, is part of Krishna Janmashtami, marking Lord Krishna's birth. Participants form human pyramids to break a clay pot filled with curd, hung 20-30 feet high, reflecting Krishna's childhood pastime of stealing butter. Now a competitive sport, teams strive to build the tallest pyramids and break the 'handis.' The festival promotes teamwork, physical fitness, and unity, showcasing its participants' communal spirit and athleticism.",
      image: handi,
    },
    {
      title: "Beltane Fire Festival",
      description:
        "Beltane, celebrated on May 1st in Scotland and Ireland, marks the start of summer with themes of renewal, fertility, and growth. Edinburgh’s modern Beltane Fire Festival honours this ancient tradition with a vibrant parade to Calton Hill, where a grand bonfire symbolises the sun’s return. The festival features lively music, dance, and performances, creating a joyful celebration of the changing seasons and the rejuvenation of life.",
      image: fire,
    },
    {
      title: "Coachella Festival",
      description:
        "Coachella, an annual music and arts festival in California, is famous for its diverse lineup, art installations, and celebrity sightings. Since its 1999 debut, it has attracted a global audience and is known for its trendy fashion and vibrant atmosphere. Held over two weekends each April, Coachella has showcased iconic performers like Beyoncé, Radiohead, and Kendrick Lamar. The festival's growing popularity has made it a cultural phenomenon, celebrating music and artistic expression on a grand scale.",
      image: cfes,
    },
    {
      title: "TOMORROWLAND- BELGIUM",
      description:
        "Tomorrowland Belgium, held annually in Boom, is one of the world's largest electronic dance music festivals. Since its 2005 debut, it has become a global cultural phenomenon, attracting music lovers worldwide. Known for its stunning stage designs, epic performances, and top DJs, Tomorrowland offers an immersive experience that transports attendees into a vibrant world of music and celebration. As an iconic event in the electronic music scene, the festival evolves each year, delivering new surprises and unforgettable moments.",
      image: tland,
    },
    {
      title: "Snowbombing",
      description:
        "Snowbombing is an annual music festival in Mayrhofen, Austria, combining skiing, snowboarding, and electronic music. As one of Europe’s premier winter festivals, it hosts top international DJs and live acts in unique venues such as igloo raves and mountain stages. Alongside the music, Snowbombing offers activities such as mountain yoga, street parties, and ski lessons. This festival creates an unforgettable experience in a breathtaking alpine setting.",
      image: snow,
    },
    {
      title: "Ganesh Chaturthi",
      description:
        "Ganesh Chaturthi is a joyous festival that unites people in devotion to Lord Ganesha. It is celebrated over ten days in August or September. Throughout the celebration, devotees offer prayers sweets, and perform aarti rituals. On the tenth day, the idol is immersed in water during a lively procession, symbolising Ganesha's return to the divine realm. This festival fosters community spirit and cultural exchange, bringing together people from diverse backgrounds.",
      image: ganesh,
    },
    {
      title: "Diwali Festival",
      description:
        "Diwali, the 'Festival of Lights,' is a five-day celebration in India and South Asia that symbolises the victory of good over evil. It commemorates Lord Rama’s return from exile with Sita and Lakshmana. During Diwali, homes are decorated with lights, lamps, and rangolis while families exchange gifts, share sweets, and enjoy fireworks. The festival is a time for prayers and blessings, fostering unity among diverse communities and spreading joy and happiness across all backgrounds.",
      image: diwali,
    },
  ];
  return (
    <>
      <PageTop title="Event Journey" img={Img0} />
      <Pagebreak greyBreakPage={true} />
      <ServicePage
        bg="white"
        // title="Event Journey"
        subdesc=""
        heading="Our Event Journey"
        listtitle=""
        imgurl={Img1}
        desc="
        Holi is also known as the festival of colours in India. It is also referred to as the festival of love and compassion. Celebrations are typically marked by splashing of colours on family and friends. It is a spring festival and perhaps the happiest and most colorful religious festival of the Hindus. The festival is generally celebrated on Phalgun Purima (February - March) and people greet each other by rubbing colour on each other's body or face and saying 'Bura Na Manno Holi Hain'."
      />
      <div className="swiper-card-parent parent">
        <div className="cont swiper-cont event-swiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false, // Allows Swiper to pause on interaction
              pauseOnMouseEnter: true, // Stops autoplay on mouse enter
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              "@1.50": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination,Autoplay]}
            className="event-swiper"
            navigation={true}
            // modules={[Navigation]}
          >
            {eventSlides &&
              eventSlides.map((data, index) => {
                return (
                  <SwiperSlide>
                    <div className="swiper-card" key={index}>
                      <div className="swiper-left bg-img-cover" style={{background : `url(${data.image})`}}>
                        
                      </div>
                      <div className="swiper-right">
                        <h4 className="swiper-title">{data.title}</h4>
                        <div className="s-card-text">
                         {data.description}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default EventJourney;
